// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
const authConfig = {
    apiKey: "AIzaSyCJn3h5MELpX5UQS3MlWmWVTCgkp_FfDnk",
    authDomain: "imab-auth.firebaseapp.com",
    projectId: "imab-auth",
    storageBucket: "imab-auth.appspot.com",
    messagingSenderId: "96774304840",
    appId: "1:96774304840:web:7189c0a079ba513077547d",
    measurementId: "G-2C5FVQY1M6"
};

const dbConfig = {
  apiKey: "AIzaSyDd0-kufd4E7zBRDTdGsHD0VRKoH2SIAWY",
  authDomain: "imab-complaints.firebaseapp.com",
  projectId: "imab-complaints",
  storageBucket: "imab-complaints.appspot.com",
  messagingSenderId: "13127157446",
  appId: "1:13127157446:web:2b09586b2c97980d94b9c7",
  measurementId: "G-DERLL33JLP"
};

const storageConfig = {
  apiKey: "AIzaSyCYch1dt4NpJJwCFnKSu0LwhE0ia-tWewo",
  authDomain: "imab-storage.firebaseapp.com",
  projectId: "imab-storage",
  storageBucket: "imab-storage.firebasestorage.app",
  messagingSenderId: "548648195523",
  appId: "1:548648195523:web:182746f47240e189e4d976",
  measurementId: "G-6QZ825EGMB"
};

const authApp = initializeApp(authConfig, 'AUTH_APP');
const dbApp = initializeApp(dbConfig, 'DB_APP');
const storageApp = initializeApp(storageConfig, 'STORAGE_APP');
const auth = getAuth(authApp);
const db = getFirestore(dbApp);
const storage = getStorage(storageApp);

console.log('Storage app initialized with bucket:', storage.app.options.storageBucket);

export { auth, db, storage };