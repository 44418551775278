<template>
    <div class="mt-4 p-3 border-3 border-200 border-round bg-gray-800">
        <h3 class="text-2xl text-gray-400 font-light mb-3 mt-0 pt-0">Close Complaint</h3>
        <div class="formgrid grid">
            <div class="field col-12">
                <label for="closeMessage" class="block mb-2">Message</label>
                <Textarea id="closeMessage" v-model="closeTicketForm.message" rows="3" class="w-full" :disabled="isClosingTicket || !canCloseTicket" />
            </div>
            <div class="field col-6">
                <label for="deduction" class="block mb-2">Deduction</label>
                <InputNumber id="deduction" v-model="closeTicketForm.deduction" :min="0" class="w-full" :disabled="isClosingTicket || !canCloseTicket" />
            </div>
            <div class="field col-6">
                <label for="score" class="block mb-2">Score</label>
                <InputNumber id="score" v-model="closeTicketForm.score" class="w-full" :disabled="isClosingTicket || !canCloseTicket" />
            </div>
            <div class="col-12">
                <Button label="Close Ticket" icon="pi pi-check" @click="closeTicket" class="mt-3" :loading="isClosingTicket" :disabled="isClosingTicket || !canCloseTicket" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';
import { auth } from '@/firebaseConfig';
import { useCloseComplaint } from '@/composables/complaints/useCloseComplaint';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import InputNumber from 'primevue/inputnumber';

const props = defineProps({
    complaint: {
        type: Object,
        required: true
    }
});

const emit = defineEmits(['complaint-closed']);

const closeTicketForm = ref({
    message: '',
    deduction: 0,
    score: 0
});

const isClosingTicket = ref(false);
const { closeComplaint } = useCloseComplaint();

const canCloseTicket = computed(() => {
    const currentUser = auth.currentUser;
    if (!currentUser) return false;
    
    const authorizedEmails = ['spring@theorion.pk', 'maverick@theorion.pk', 'apex@theorion.pk'];
    return authorizedEmails.includes(currentUser.email);
});

const closeTicket = async () => {
    try {
        if (!props.complaint?.id) return;
        isClosingTicket.value = true;

        const updatedComplaint = await closeComplaint(props.complaint, closeTicketForm.value);
        closeTicketForm.value = { message: '', deduction: 0, score: 0 };
        emit('complaint-closed', updatedComplaint);
    } catch (error) {
        console.error('Error closing ticket:', error);
    } finally {
        isClosingTicket.value = false;
    }
};
</script>