// composables/api/useLogs.js
import { ref, watch } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

const LOGS_CACHE_KEY = 'logsData';

export function useLogs() {
  const saveToLocalStorage = (data) => {
    localStorage.setItem(LOGS_CACHE_KEY, JSON.stringify(data));
  };

  const getFromLocalStorage = () => {
    const cachedData = localStorage.getItem(LOGS_CACHE_KEY);
    return cachedData ? JSON.parse(cachedData) : [];
  };

  // Initialize allLogs with data from localStorage
  const allLogs = ref(getFromLocalStorage());
  const allLogsLoading = ref(false);
  const allLogsError = ref(null);

  const handleError = (err) => {
    if (err.response && err.response.data && err.response.data.message) {
      return err.response.data.message;
    }
    return 'An unexpected error occurred. Please try again later.';
  };

  const fetchAllLogs = async (
    employeeId = 0, 
    companyId = 0, 
    section = 'all', 
    startDate = null, 
    endDate = null,
    userEmail = 'all'
  ) => {
    allLogsLoading.value = true;
    allLogsError.value = null;

    try {
      allLogs.value = getFromLocalStorage();

      const url = API_ENDPOINTS.fetchLogs
        .replace('{employeeId}', employeeId)
        .replace('{companyId}', companyId)
        .replace('{section}', section)
        .replace('{startDate}', startDate || 'None')
        .replace('{endDate}', endDate || 'None')
        .replace('{userEmail}', userEmail || 'all');

      const response = await axiosClient.get(url);
      
      if (Array.isArray(response.data)) {
        saveToLocalStorage(response.data);
        allLogs.value = response.data;
      } else {
        console.warn('Received non-array data from API:', response.data);
        saveToLocalStorage([]);
        allLogs.value = [];
      }
    } catch (err) {
      allLogsError.value = handleError(err);
      saveToLocalStorage([]);
      allLogs.value = [];
    } finally {
      allLogsLoading.value = false;
    }
  };

  // Watch for changes in allLogs and update localStorage
  watch(allLogs, (newValue) => {
    saveToLocalStorage(newValue);
  }, { deep: true });

  return {
    allLogs,
    allLogsLoading,
    allLogsError,
    fetchAllLogs
  };
}