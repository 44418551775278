import { firestoreDB } from '@/firestore';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/firebaseConfig';

export function useUpdateComplaint() {
    const updateComplaint = async (complaintId, updateData) => {
        try {
            // First update the document
            await firestoreDB.updateDocument('complaints', complaintId, updateData);
            
            // Then fetch the updated document
            const docRef = doc(db, 'complaints', complaintId);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
                return { id: docSnap.id, ...docSnap.data() };
            } else {
                throw new Error('Document not found after update');
            }
        } catch (error) {
            console.error('Error updating complaint:', error);
            throw error;
        }
    };

    return {
        updateComplaint
    };
}
