import { db, auth } from './firebaseConfig';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc, query, where, orderBy } from 'firebase/firestore';

export const firestoreDB = {
  async addDocument(collectionName, data) {
    try {
      // Ensure the user is authenticated before adding a document
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }
      const docRef = await addDoc(collection(db, collectionName), data);
      console.log("Document written with ID: ", docRef.id);
      return docRef.id;
    } catch (e) {
      console.error("Error adding document: ", e);
      throw e;
    }
  },

  async getDocuments(collectionName) {
    try {
      // Ensure the user is authenticated before getting documents
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }
      const q = query(
        collection(db, collectionName),
        orderBy('complaint_date', 'desc')
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (e) {
      console.error("Error getting documents: ", e);
      throw e;
    }
  },

  async updateDocument(collectionName, docId, data) {
    try {
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }
      const docRef = doc(db, collectionName, docId);
      await updateDoc(docRef, data);
      console.log("Document updated successfully");
    } catch (e) {
      console.error("Error updating document: ", e);
      throw e;
    }
  },

  async deleteDocument(collectionName, docId) {
    try {
      // Ensure the user is authenticated before deleting a document
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }
      await deleteDoc(doc(db, collectionName, docId));
      console.log("Document deleted successfully");
    } catch (e) {
      console.error("Error deleting document: ", e);
      throw e;
    }
  },

  async getFilteredDocuments(collectionName, field, value) {
    try {
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }
      const q = query(
        collection(db, collectionName), 
        where(`${field}.id`, '==', value),
        orderBy('complaint_date', 'desc')
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (e) {
      console.error("Error getting filtered documents: ", e);
      throw e;
    }
  },

  async getFilteredDocumentsWithDate(collectionName, employeeId = null, date = null) {
    try {
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }

      // Start of the selected month
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      // End of the selected month
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);

      let q;
      
      if (employeeId) {
        q = query(
          collection(db, collectionName),
          where('employee.id', '==', employeeId),
          where('createdby.timestamp', '>=', startDate),
          where('createdby.timestamp', '<=', endDate)
        );
      } else {
        q = query(
          collection(db, collectionName),
          where('createdby.timestamp', '>=', startDate),
          where('createdby.timestamp', '<=', endDate)
        );
      }

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (e) {
      console.error("Error getting filtered documents: ", e);
      throw e;
    }
  },

  async getFilteredDocumentsByDate(collectionName, date) {
    try {
      if (!auth.currentUser) {
        throw new Error('User not authenticated');
      }

      // Start of the selected month
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      // End of the selected month
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);

      const q = query(
        collection(db, collectionName),
        where('complaint_date', '>=', startDate),
        where('complaint_date', '<=', endDate),
        orderBy('complaint_date', 'desc')
      );

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (e) {
      console.error("Error getting filtered documents by date: ", e);
      throw e;
    }
  }
};
