import { ref, onUnmounted } from 'vue';
export function useLondonUnixTime() {
    const getLondonDate = (date = new Date()) => {
      const londonTime = new Intl.DateTimeFormat('en-GB', {
        timeZone: 'Europe/London',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).format(date);
  
      const [datePart, timePart] = londonTime.split(', ');
      const [day, month, year] = datePart.split('/');
      const [hour, minute, second] = timePart.split(':');
      
      return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
    };
  
    const getUnixTimeInThreeHours = () => {
      const now = getLondonDate();
      now.setHours(now.getHours() + 3);
      return getUnixTime(now);
    };
    
    const getUnixTime = (date) => {
      return date.getTime(); // Returns milliseconds
    };
  
    const getLondonUnixTime = () => {
      return getUnixTime(getLondonDate());
    };
  
    const getStartOfDay = (date) => {
      const londonDate = getLondonDate(date);
      londonDate.setUTCHours(0, 0, 0, 0);
      return londonDate;
    };
  
    const getEndOfDay = (date) => {
      const londonDate = getLondonDate(date);
      londonDate.setUTCHours(23, 59, 59, 999);
      return londonDate;
    };
  
    const startOfCurrentDay = () => {
      return getUnixTime(getStartOfDay(new Date()));
    };
  
    const endOfCurrentDay = () => {
      return getUnixTime(getEndOfDay(new Date()));
    };
  
    const startOfYesterday = () => {
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
      return getUnixTime(getStartOfDay(yesterday));
    };
  
    const endOfYesterday = () => {
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
      return getUnixTime(getEndOfDay(yesterday));
    };
  
    // New functions
    const formatDate = (date) => {
      return date.toLocaleString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'Europe/London'
      });
    };
  
    const getTodayDate = () => {
      return formatDate(getStartOfDay(new Date()));
    };
  
    const getYesterdayDate = () => {
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
      return formatDate(getStartOfDay(yesterday));
    };

    const generateCurrentTimeunix = ref(getLondonUnixTime());
    const updateCurrentTime = () => {
      generateCurrentTimeunix.value = getLondonUnixTime();
    };
    // Update time every minute (60000 milliseconds)
    const intervalId = setInterval(updateCurrentTime, 60000);

    onUnmounted(() => {
      clearInterval(intervalId);
    });
  
    const getStartOfMonth = (date = new Date()) => {
      const londonDate = getLondonDate(date);
      const firstDay = new Date(londonDate.getFullYear(), londonDate.getMonth(), 1);
      return getStartOfDay(firstDay);
    };

    const getEndOfMonth = (date = new Date()) => {
      const londonDate = getLondonDate(date);
      const lastDay = new Date(londonDate.getFullYear(), londonDate.getMonth() + 1, 0);
      return getEndOfDay(lastDay);
    };
  
    return {
      generateCurrentTimeunix,
      getUnixTimeInThreeHours,
      getLondonUnixTime,
      getLondonDate,
      getStartOfDay,
      getEndOfDay,
      startOfCurrentDay,
      endOfCurrentDay,
      startOfYesterday,
      endOfYesterday,
      getTodayDate,
      getYesterdayDate,
      getStartOfMonth,
      getEndOfMonth
    };
  }

export function useLondonClock() {
  const londonTime = ref('');

  const getLondonTimeString = () => {
    return new Intl.DateTimeFormat('en-GB', {
      timeZone: 'Europe/London',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }).format(new Date());
  };

  const updateTime = () => {
    londonTime.value = getLondonTimeString();
  };

  // Initial update
  updateTime();

  // Calculate milliseconds until next minute
  const getMillisecondsUntilNextMinute = () => {
    const now = new Date();
    return (60 - now.getSeconds()) * 1000 - now.getMilliseconds();
  };

  // Set initial timeout to align with minute change
  let timeoutId = setTimeout(() => {
    updateTime();
    // Then start the interval
    intervalId = setInterval(updateTime, 60000);
  }, getMillisecondsUntilNextMinute());

  let intervalId;

  onUnmounted(() => {
    clearTimeout(timeoutId);
    clearInterval(intervalId);
  });

  return {
    londonTime
  };
}