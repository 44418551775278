<template>
    <form @submit.prevent="handleSubmit" v-if="employee" autocomplete="off">
        <!-- Main Info Section -->
        <div class="grid mt-4">
            <!-- Photo Section -->
            <div class="col-12 md:col-3">
                <div class="mb-4">
                    <img 
                        v-if="employee.employee_photo_url" 
                        :src="employee.employee_photo_url" 
                        class="w-full max-w-15rem h-15rem object-cover mb-2 rounded-md shadow-md"
                        alt="Employee photo"
                    />
                    <img 
                        v-else
                        :src="defaultUserImage" 
                        class="w-full max-w-15rem h-15rem object-cover mb-2 rounded-md shadow-md"
                        alt="Default photo"
                    />
                    <FileUpload
                        mode="basic"
                        accept="image/*"
                        :disabled="isUpdating"
                        :maxFileSize="3000000"
                        @select="handleFileSelect"
                        :auto="false"
                        chooseLabel="Upload"
                        class="w-full"
                    />
                </div>
            </div>

            <!-- Main Info Columns -->
            <div class="col-12 md:col-9">
                <div class="grid">
                    <!-- Column 1: Basic Info -->
                    <div class="col-12 md:col-6 pl-4">
                        <div class="mb-3">
                            <label class="block text-xs text-gray-400 mb-1">
                                Employee Id <span class="text-red-500">*</span>
                            </label>
                            <InputText disabled="true" v-model="employee.employee_id" class="w-full" />
                        </div>
                        <div class="mb-3">
                            <label class="block text-xs text-gray-400 mb-1">
                                Name <span class="text-red-500">*</span>
                            </label>
                            <InputText v-model="employee.employee_name" :disabled="isUpdating" class="w-full" />
                        </div>
                        <div class="mb-3">
                            <label class="block text-xs text-gray-400 mb-1">
                                Badge ID <span class="text-red-500">*</span>
                            </label>
                            <InputText v-model="employee.employee_badge_id" :disabled="isUpdating" class="w-full" />
                        </div>
                        <div class="mb-0">
                            <label class="block text-xs text-gray-400 mb-1">
                                Badge PIN <span class="text-red-500">*</span>
                            </label>
                            <InputText v-model="employee.employee_badge_pin" :disabled="isUpdating" class="w-full" />
                        </div>
                    </div>

                    <!-- Column 2: Contact Info -->
                    <div class="col-12 md:col-6 p-3">
                        <div class="mb-3">
                            <label class="block text-xs text-gray-400 mb-1">Phone Number (Main)</label>
                            <InputText v-model="employee.employee_phone_number_main" :disabled="isUpdating" class="w-full" />
                        </div>
                        <div class="mb-3">
                            <label class="block text-xs text-gray-400 mb-1">Phone Number (Secondary)</label>
                            <InputText v-model="employee.employee_phone_number_secondary" :disabled="isUpdating" class="w-full" />
                        </div>
                        <div class="mb-3">
                            <label class="block text-xs text-gray-400 mb-1">
                                Email <span class="text-red-500">*</span>
                            </label>
                            <InputText v-model="employee.employee_email" :disabled="isUpdating" class="w-full" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Secondary Info Section -->
        <div class="grid mt-0">
            <!-- Other Info Column -->
            <div class="col-12 md:col-6 p-3">
                <h2 class="mb-3">Other Info</h2>
                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">Date of Birth</label>
                    <Calendar dateFormat="yy-mm-dd" v-model="employee.employee_dob" :disabled="isUpdating" class="w-full" />
                </div>
                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">CNIC</label>
                    <InputText v-model="employee.employee_cnic" :disabled="isUpdating" class="w-full" />
                </div>
                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">Father's Name</label>
                    <InputText v-model="employee.employee_father_name" :disabled="isUpdating" class="w-full" />
                </div>
                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">Gender</label>
                    <Dropdown v-model="employee.employee_gender" :options="genderOptions" :disabled="isUpdating" 
                            optionLabel="name" optionValue="code" class="w-full" />
                </div>
                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">Permanent Address</label>
                    <Textarea v-model="employee.employee_address_permanent" :disabled="isUpdating" 
                            rows="3" class="w-full" autoResize />
                </div>
                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">Current Address</label>
                    <Textarea v-model="employee.employee_address_current" :disabled="isUpdating" 
                            rows="3" class="w-full" autoResize />
                </div>

                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">Next of Kin Name</label>
                    <InputText v-model="employee.employee_next_of_kin" :disabled="isUpdating" class="w-full" />
                </div>
                <div class="mb-0">
                    <label class="block text-xs text-gray-400 mb-1">Next of Kin Phone Number</label>
                    <InputText v-model="employee.employee_next_of_kin_number" :disabled="isUpdating" class="w-full" />
                </div>
            </div>

            <!-- Contract Info Column -->
            <div class="col-12 md:col-6 p-3">
                <h2 class="mb-3">Contract Info</h2>
                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">
                        Status <span class="text-red-500">*</span>
                    </label>
                    <Dropdown v-model="employee.employee_status" :options="statusOptions" :disabled="isUpdating" 
                            optionLabel="name" optionValue="code" class="w-full" />
                </div>
                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">
                        Department <span class="text-red-500">*</span>
                    </label>
                    <Dropdown v-model="employee.employee_department" :options="departmentOptions" :disabled="isUpdating" 
                            optionLabel="name" optionValue="code" class="w-full" />
                </div>
                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">
                        Company <span class="text-red-500">*</span>
                    </label>
                    <Dropdown v-model="employee.employee_company_id" :options="companiesList" :disabled="isUpdating" 
                            optionLabel="company_name" optionValue="company_id" class="w-full" />
                </div>
                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">
                        Join Date <span class="text-red-500">*</span>
                    </label>
                    <Calendar 
                        dateFormat="yy-mm-dd" 
                        v-model="employee.employee_join_date" 
                        :disabled="isUpdating"
                        :showIcon="true"
                        class="w-full" 
                    />
                </div>
                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">
                        Contract Date <span class="text-red-500">*</span>
                    </label>
                    <Calendar 
                        dateFormat="yy-mm-dd" 
                        v-model="employee.employee_contract_date" 
                        :disabled="isUpdating"
                        :showIcon="true"
                        class="w-full" 
                    />
                </div>
                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">Contract</label>

                    <Dropdown v-model="employee.employee_contract" :options="contractOptions" :disabled="isUpdating" 
                            optionLabel="name" optionValue="code" class="w-full" />
                </div>
                <div class="mb-3">
                    <label class="block text-xs text-gray-400 mb-1">Extra Bonuses</label>
                    <InputText v-model="employee.employee_extra_bonuses" disabled="true" class="w-full" />
                </div>
            </div>
        </div>
    </form>

    <!-- Loading and Error States -->
    <div v-else-if="loading" class="h-10rem">
        <div class="imab-logo-animated" style="left: 30%; top: 10%;">
            <div><span class="I">I</span><span class="M">M</span><span class="A">A</span><span class="B">B</span></div>
        </div>
    </div> 
    <p v-else-if="error">{{ error }}</p>

    <!-- Footer -->
    <footer class="flex overflow-hidden mt-3">
        <div class="flex-shrink-0 flex align-items-start justify-content-start mr-2 mt-1">
            <Button 
                label="Cancel" 
                :disabled="isUpdating || isUploading" 
                class="surface-200 hover:surface-400" 
                @click="handleBack" 
                aria-label="Cancel and go back" 
            />
        </div>
        <div class="flex-grow-1 flex align-items-start justify-content-end">
            <Button 
                label="Edit Employee" 
                :disabled="loading || isUpdating || isUploading" 
                :loading="isUpdating || isUploading" 
                class="w-full bg-primary hover:surface-900 px-3 mt-1" 
                @click="handleSubmit" 
                aria-label="Submit" 
            />
        </div>
    </footer>
</template>

<script setup>
import { onMounted, defineProps, defineEmits, ref, watch } from 'vue';
import { useEmployeeSingle } from '@/composables/api/useEmployeeSingle';
import { useEmployeeSingleUpdate } from '@/composables/api/useEmployeeSingleUpdate';
import { useEmployees } from '@/composables/api/useEmployees';
import { useToast } from 'primevue/usetoast';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import FileUpload from 'primevue/fileupload';
import { useFileUpload } from '@/composables/useFileUpload';
import { storage } from '@/firebaseConfig';
import { deleteObject } from 'firebase/storage';
import { ref as storageRef } from 'firebase/storage';
import defaultUserImage from '@/assets/default-user.png';

const props = defineProps({
    selectedId: {
        type: Number,
        required: true
    }
});

const emit = defineEmits(['handleBack', 'employeeUpdated']);

const { companiesList } = useEmployees();
const { employee, loading, error, fetchEmployeeById } = useEmployeeSingle();
const { isUpdating, updateEmployee } = useEmployeeSingleUpdate();
const toast = useToast();
const { uploadFile, isUploading } = useFileUpload();

const statusOptions = [   
  { name: 'Permanent', code: 'Permanent' },
  { name: 'Probation', code: 'Probation' },
  { name: 'Trainee', code: 'Trainee' },
  { name: 'Resigned', code: 'Resigned' },
  { name: 'Terminated', code: 'Terminated' },
  { name: 'On Hold', code: 'On Hold' },
  { name: 'Cancelled', code: 'Cancelled' }
];

const departmentOptions = [   
  { name: 'CSR Staff', code: 'HR / Operator' },
  { name: 'Covering Staff', code: 'HR / Shift Cover' },
  { name: 'House keeping', code: 'House Keeping' },
  { name: 'Tech Staff', code: 'Tech' },
  { name: 'Manager', code: 'Manager' },
  { name: 'Maintenance', code: 'Maintenance' }
];

const genderOptions = [
    { name: 'Male', code: 'Male' },
    { name: 'Female', code: 'Female' },
    { name: 'Other', code: 'Other' }
];

const contractOptions = [
    { name: 'AUTOMATED 10', code: 'AUTOMATED' },
    { name: 'AUTOMATED 8', code: 'AUTOMATED8' },
    { name: 'MANAGEMENT', code: 'MANAGEMENT' },
    { name: 'MANUAL', code: 'MANUAL' },
];

const selectedFile = ref(null);

const formatDateForAPI = (date) => {
    if (!date) return null;
    if (typeof date === 'string') return date;
    
    // Get year, month and day components and create a YYYY-MM-DD string
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
};

const parseAPIDate = (dateString) => {
    if (!dateString) return null;
    
    // Split the date string and create a new date using components
    const [year, month, day] = dateString.split('-');
    return new Date(year, month - 1, day);
};

onMounted(() => {
    fetchEmployeeById(props.selectedId);
});

const handleBack = () => {
    emit('handleBack');
};

const handleSubmit = async () => {
    const requiredFields = [
        'employee_name',
        'employee_email',
        'employee_badge_id',
        'employee_badge_pin',
        'employee_department',
        'employee_company_id',
        'employee_status',
        'employee_join_date',
        'employee_contract_date'
    ];

    const missingFields = requiredFields.filter(field => !employee.value[field]);

    if (missingFields.length > 0) {
        toast.add({
            severity: 'error',
            summary: 'Validation Error',
            detail: `Please fill in all required fields: ${missingFields.join(', ')}`,
            life: 5000,
            group: 'br'
        });
        return;
    }

    try {
        if (selectedFile.value) {
            const fileExtension = selectedFile.value.name.split('.').pop();
            const path = `employee-photos/${employee.value.employee_id}.${fileExtension}`;
            
            if (employee.value.photo_url) {
                try {
                    const oldRef = storageRef(storage, `employee-photos/${employee.value.employee_id}.*`);
                    await deleteObject(oldRef);
                } catch (error) {
                    console.warn('Failed to delete old photo:', error);
                }
            }
            
            const photoUrl = await uploadFile(selectedFile.value, path);
            employee.value.employee_photo_url = photoUrl;
        }

        const changedFields = {
            employee_id: employee.value.employee_id,
            employee_join_date: formatDateForAPI(employee.value.employee_join_date),
            employee_contract_date: formatDateForAPI(employee.value.employee_contract_date)
        };

        for (const [key, value] of Object.entries(employee.value)) {
            if (key !== 'employee_id' && 
                key !== 'employee_join_date' && 
                key !== 'employee_contract_date' && 
                JSON.stringify(value) !== JSON.stringify(initialEmployeeData.value[key])) {
                changedFields[key] = value;
            }
        }

        if (Object.keys(changedFields).length === 1) {
            toast.add({
                severity: 'info',
                summary: 'No Changes',
                detail: 'No changes were made to the employee data.',
                life: 3000,
                group: 'br'
            });
            return;
        }

        const updatedEmployee = await updateEmployee(changedFields);
        toast.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Employee updated successfully',
            life: 3000,
            group: 'br'
        });
        
        selectedFile.value = null;
        
        initialEmployeeData.value = JSON.parse(JSON.stringify(employee.value));
        emit('employeeUpdated', updatedEmployee);
    } catch (err) {
        let errorMessage = 'An error occurred while updating the employee';
        if (err.response && err.response.data) {
            if (err.response.status === 409) {
                errorMessage = `${err.response.data.error} Field: ${err.response.data.field}`;
            } else {
                errorMessage = err.response.data.error || errorMessage;
            }
        }
        toast.add({
            severity: 'error',
            summary: 'Update Failed',
            detail: errorMessage,
            life: 5000,
            group: 'br'
        });
    }
};

const handleFileSelect = (event) => {
    selectedFile.value = event.files[0];
};

const initialEmployeeData = ref(null);

watch(employee, (newValue) => {
    if (newValue && !initialEmployeeData.value) {
        if (newValue.employee_join_date) {
            newValue.employee_join_date = parseAPIDate(newValue.employee_join_date);
        }
        if (newValue.employee_contract_date) {
            newValue.employee_contract_date = parseAPIDate(newValue.employee_contract_date);
        }
        initialEmployeeData.value = JSON.parse(JSON.stringify(newValue));
    }
}, { immediate: true });
</script>

<style scoped>
.employee-photo {
    object-fit: cover;
    border-radius: 4px;
}
</style>