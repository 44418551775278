// composables/useRoster.js
import { ref, computed, onMounted } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';
import { useEmployeePhotos } from '@/composables/useEmployeePhotos';

const getStorageKey = (type) => `all${type.charAt(0).toUpperCase() + type.slice(1)}`;

export function useRoster(viewType = 'roster') {
  const allRosters = ref([]);
  const rosterLoading = ref(false);
  const rosterErrors = ref(null);
  const { attachEmployeePhotos } = useEmployeePhotos();

  const safeAllRosters = computed(() => {
    return Array.isArray(allRosters.value) ? allRosters.value : [];
  });

  const setRosters = (rosters) => {
    const rostersWithPhotos = attachEmployeePhotos(rosters);
    allRosters.value = Array.isArray(rostersWithPhotos) ? rostersWithPhotos : [];
    localStorage.setItem(getStorageKey(viewType), JSON.stringify(allRosters.value));
  };

  const fetchAllRosters = async (employeeId, companyId, startDate, endDate) => {
    rosterLoading.value = true;
    rosterErrors.value = null;

    try {
      const url = viewType === 'leaves' 
        ? API_ENDPOINTS.fetchLeaves
            .replace('{employeeId}', employeeId)
            .replace('{companyId}', companyId)
            .replace('{startDate}', startDate || 'None')
            .replace('{endDate}', endDate || 'None')
        : API_ENDPOINTS.fetchRosters
            .replace('{employeeId}', employeeId)
            .replace('{companyId}', companyId)
            .replace('{startDate}', startDate || 'None')
            .replace('{endDate}', endDate || 'None');

      const response = await axiosClient.get(url);
      
      if (response.data && Array.isArray(response.data)) {
        setRosters(response.data);
      } else {
        console.warn('Received non-array data from API:', response.data);
        setRosters([]);
      }
    } catch (err) {
      rosterErrors.value = err.response?.data?.message || err.message || 
        `Failed to fetch ${viewType} data`;
      setRosters([]);
    } finally {
      rosterLoading.value = false;
    }
  };

  const initializeFromLocalStorage = () => {
    const storedRosters = localStorage.getItem(getStorageKey(viewType));
    if (storedRosters) {
      try {
        const parsedRosters = JSON.parse(storedRosters);
        const rostersWithPhotos = attachEmployeePhotos(parsedRosters);
        allRosters.value = Array.isArray(rostersWithPhotos) ? rostersWithPhotos : [];
      } catch (error) {
        console.error('Error parsing stored rosters:', error);
        allRosters.value = [];
      }
    }
  };

  onMounted(() => {
    initializeFromLocalStorage();
  });

  return {
    allRosters: safeAllRosters,
    rosterLoading,
    rosterErrors,
    fetchAllRosters
  };
}