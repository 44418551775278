import { ref } from 'vue';
import { storage, auth } from '@/firebaseConfig';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';

export function useFileUpload() {
  const uploadProgress = ref(0);
  const uploadError = ref(null);
  const uploadURL = ref(null);
  const isUploading = ref(false);

  const compressImage = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const MAX_WIDTH = 200;
          const MAX_HEIGHT = 200;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            }));
          }, 'image/jpeg', 0.7); // 70% quality
        };
      };
    });
  };

  const uploadFile = async (file, path) => {
    try {
      if (!auth.currentUser) {
        throw new Error('User must be authenticated to upload files');
      }

      isUploading.value = true;
      uploadError.value = null;
      uploadProgress.value = 0;

      // Compress image before upload
      const compressedFile = await compressImage(file);
      
      const fileRef = storageRef(storage, path);
      const snapshot = await uploadBytes(fileRef, compressedFile);
      uploadProgress.value = 100;
      
      const url = await getDownloadURL(snapshot.ref);
      uploadURL.value = url;
      
      return url;
    } catch (error) {
      console.error('Upload error details:', error);
      uploadError.value = error.message;
      throw error;
    } finally {
      isUploading.value = false;
    }
  };

  return {
    uploadProgress,
    uploadError,
    uploadURL,
    isUploading,
    uploadFile
  };
}
