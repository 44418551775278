<!-- Components/dashboard/EmployeeCard.vue -->
<template>
  <div :class="cardClasses">
    <!-- Shortcut -->
    <div class="absolute bottom-0 right-0 z-1 shortcut">
      <div class="flex justify-content-end flex-wrap">
          <div class="flex align-items-center justify-content-center">
            <!-- Roster: Edit -->
            <Button icon="pi pi-pencil" class="surface-300 hover:surface-500" @click="openDialog(title)" />
            <!-- Roster: Logs -->
            <Button icon="pi pi-code" class="surface-300 hover:surface-500" @click="openDialog('LOGS')" />
            <!-- Roster: Profile -->
            <Button icon="pi pi-sitemap" class="surface-300 hover:surface-500" @click="openDialog('PROFILE', rosterLog.employee_id, rosterLog.employee_company_id)" />
          </div>
      </div>
    </div> 
    <!-- Employee Photo -->
    <div class="employee-photo-small">
      <div class="employee-cover-status" v-if="showCoverStatus">COVER</div>
      <div class="employee-shift-status" v-else>{{ rosterLog.employee_company_name_short }}</div>
      <img :src="rosterLog.employee_photo_url || defaultUserImage" alt="Employee Photo"/>
    </div>
    <!-- Employee Content -->
    <div class="employee-content">
      <!-- Employee Name -->
      <h3 class="employee-name">{{ rosterLog.employee_name }}</h3>
      <!-- Employee Company Name -->
      <p class="employee-shifttime" v-if="title=='COVERS'">{{ truncatedCompanyName }}</p>
      <!-- Shift: Check ONGOING or NOT LOGOUT -->
      <p class="employee-checkintime" v-if="title !='UPCOMING' && title !='LEAVES'">
        <template v-if="typeof checkShiftStatus === 'string'">
          {{ checkShiftStatus }}
        </template>
        <template v-else-if="checkShiftStatus && checkShiftStatus.time">
          {{ checkShiftStatus.time }} 
          <span class="status-text opacity-60 font-light">{{ checkShiftStatus.status }}</span>
        </template>
      </p>
      <!-- Shift: Show Shift Time -->
      <p class="employee-shifttime">{{ shiftTimeDisplay }}</p>
      <!-- Shift: Early Time Counter (Shift IN)-->
      <div v-if="rosterLog.attendance_status_in==='EARLY'">
        <p class="employee-timecounter" v-if="showCounter==true && title=='EARLY'">
          <i class="pi pi-arrow-up icon"></i> 
          {{ calculateTimeRemaining(rosterLog.attendance_in, rosterLog.event_start, 'm') }}
        </p>
      </div>
      <!-- Shift: Early Time Counter (Shift OUT)-->
      <div v-else-if="rosterLog.attendance_status_out==='EARLY'">
        <p class="employee-timecounter" v-if="showCounter==true && title=='EARLY'">
          <i class="pi pi-arrow-down icon"></i> 
          {{ calculateTimeRemaining(rosterLog.attendance_out, rosterLog.event_end, 'm') }}
        </p>
      </div>
      <!-- Shift: On Time Counter -->
      <p class="employee-timecounter" v-else-if="showCounter==true && title=='ONTIME'">
        <i class="pi pi-arrow-up icon"></i> 
        {{ calculateTimeRemaining(rosterLog.attendance_in, rosterLog.event_start, 'm') }}
      </p>
      <!-- Shift: Late Time Counter -->
      <p class="employee-timecounter" v-else-if="showCounter==true && title=='LATE'">
        <i class="pi pi-arrow-down icon"></i> 
        {{ calculateTimeRemaining(rosterLog.event_start, rosterLog.attendance_in, 'm') }}
      </p>
      <!-- Shift: Over Time Counter -->
      <p class="employee-timecounter" v-else-if="showCounter==true && title=='OVERTIME'">
        <i class="pi pi-arrow-up icon"></i> 
        {{ calculateTimeDifferenceInHours(rosterLog.event_end, rosterLog.attendance_out, 'h') }}
      </p>
      <!-- Roster: Upcoming Shift Time Counter -->
      <p class="employee-timecounter" v-if="showCounter && title === 'UPCOMING'">{{ displayCountdown }}</p>
      <!-- Shift: Not Logout Time Counter -->
      <p class="employee-timecounter" v-else-if="showCounter==true && title=='NOTLOGOUT'">
        {{ calculateTimeRemaining(rosterLog.event_end, generateCurrentTimeunix, 'm') }}
      </p>
      <!-- Shift: Worked Hours Counter -->
      <p class="employee-timecounter workedcounter" 
         v-else-if="showCounter==true && rosterLog.attendance_out !== null && title !='LEAVES'"
         :class="workedHoursStatusClass">
         <i v-if="workedHoursDisplay === 'ERROR'" class="pi pi-exclamation-triangle"></i> 
         <i v-else-if="workedHoursDisplay === 'OPEN'" class="pi pi-exclamation-triangle"></i>
         <i v-else class="pi pi-check"></i>
         {{ workedHoursDisplay }}
      </p>
      <!-- Roster: Leaves Indicator -->
      <p class="employee-timecounter" v-else-if="title =='LEAVES' && rosterLog.event_type !== 'SHIFT' && rosterLog.event_type !== 'COVER'">L</p>
      <!-- Roster: No Show Indicator -->
      <p class="employee-timecounter" v-else-if="title =='LEAVES' && (rosterLog.event_type == 'SHIFT' || rosterLog.event_type == 'COVER')">N</p>
    </div>
  </div>

  <!-- Roster Edit Dialog -->
  <Dialog v-model:visible="editRosterDialog" :style="{ width: '60vw' }" :modal="true" maximizable>
    <template #header>
      <h3>Roster Edit</h3>
    </template>
    <RosterUpdateDialog :selectedRosterIds="[rosterLog.event_id]" @updateSuccess="handleUpdateSuccess" @handleBack="handleBack" />
  </Dialog>

  <!-- Attendance Edit Dialog -->
  <Dialog v-model:visible="editAttendanceDialog" :style="{ width: '60vw' }" :modal="true" maximizable>
    <template #header>
      <h3>Attendance Edit</h3>
    </template>
    <AttendanceUpdateDialog :selectedIds="{ employee_id: rosterLog.employee_id, roster_id: rosterLog.event_id, attendance_id: rosterLog.attendance_id_in}" 
                            @updateSuccess="handleUpdateSuccess" @handleBack="handleBack" />
  </Dialog>

  <!-- Logs Dialog -->
  <Dialog v-model:visible="viewLogs" :style="{ width: '60vw' }" :modal="true">
    <template #header>
      <h2>Logs</h2>
    </template>
    <LogsDialog :selectedIds="rosterLog.event_id" logType="roster" @handleBack="handleBack" />
  </Dialog>
</template>

<script setup>
import { ref, computed, onUnmounted, defineEmits, defineProps, watchEffect } from 'vue';
import { useTimeCalculation } from '@/composables/time/useTimeCalculation';
import { useLondonUnixTime } from '@/composables/time/useLondonTime';
import { useUserSelectionStore } from '@/stores/userSelectionStore';
import { useUtils } from '@/composables/useUtils';
import { useRouter } from 'vue-router';
import LogsDialog from '@/components/dialogs/LogsDialog.vue';
import RosterUpdateDialog from '@/components/dialogs/RosterUpdateDialog.vue';
import AttendanceUpdateDialog from '@/components/dialogs/AttendanceUpdateDialog.vue';
import defaultUserImage from '@/assets/default-user.png';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

const router = useRouter();
const userSelectionStore = useUserSelectionStore();
const { generateCurrentTimeunix } = useLondonUnixTime();
const { calculateNetWorkedHours } = useUtils();

const editRosterDialog = ref(false);
const editAttendanceDialog = ref(false);
const viewLogs = ref(false);

// Props
const props = defineProps({
  title: String,
  rosterLog: Object,
  showCounter: Boolean,
  loadingIndicator: Boolean,
  attendanceLoadingIndicator: Boolean
});

// Utils: Time Calculation
const { calculateTimeRemaining, formatTime, calculateTimeDifferenceInHours } = useTimeCalculation()

// Dialog: Edit Roster, Edit Attendance, View Logs
const openDialog = (title, employee_id = null, employee_company_id = null) => {
  if (title === 'PROFILE' && employee_id > 0) {
    userSelectionStore.setEmployeeId(employee_id);
    userSelectionStore.setCompanyId(employee_company_id);
    router.push('/profiles');
  } else if (title === 'LOGS') {
    viewLogs.value = true;
  } else {
    const dialogRef = title === "UPCOMING" || title === "LEAVES" ? editRosterDialog : editAttendanceDialog;
    dialogRef.value = true;
  }
};

// Computed: Check if upcoming shift
const isUpcoming = computed(() => props.title === 'UPCOMING');

// Roster: Upcoming Shift Time Counter
const countdownValue = ref('00:00:00');
let intervalId = null;

// Computed: Check if show counter
const shouldShowCounter = computed(() => props.showCounter && isUpcoming.value);

// Function: Update countdown
const updateCountdown = () => {
  if (shouldShowCounter.value && props.rosterLog) {
    countdownValue.value = calculateTimeRemaining(generateCurrentTimeunix, props.rosterLog.event_start, 'm');
  }
};

// Function: Reset and start countdown
const resetAndStartCountdown = () => {
  clearInterval(intervalId);
  updateCountdown();
  intervalId = setInterval(updateCountdown, 60000);
};

// Use watchEffect for more concise reactivity
watchEffect(() => {
  if (shouldShowCounter.value) {
    resetAndStartCountdown();
  } else {
    clearInterval(intervalId);
  }
});

// Unmount: Clear interval
onUnmounted(() => clearInterval(intervalId));

// Computed: Display countdown
const displayCountdown = computed(() => shouldShowCounter.value ? countdownValue.value : '');

// Emits
const emit = defineEmits(['updateSuccess']);

// Function: Handle update success
const handleUpdateSuccess = () => {
  emit('updateSuccess');
  editRosterDialog.value = false;
  editAttendanceDialog.value = false;
  viewLogs.value = false;
  resetAndStartCountdown();
};

// Function: Handle back
const handleBack = () => {
  editRosterDialog.value = false;
  editAttendanceDialog.value = false;
  viewLogs.value = false;
};

// Computed: Calculate worked hours
const workedHoursDisplay = computed(() => {
  return calculateNetWorkedHours(
    props.rosterLog.event_start,
    props.rosterLog.event_end,
    props.rosterLog.attendance_in,
    props.rosterLog.attendance_out,
    props.rosterLog.attendance_status_in,
    props.rosterLog.attendance_status_out,
    props.rosterLog.over_below_status
  );
});

// Computed: assign class to worked hours based on status
const workedHoursStatusClass = computed(() => ({
  'worked-hours-error': workedHoursDisplay.value === 'ERROR',
  'worked-hours-open': workedHoursDisplay.value === 'OPEN',
  'worked-hours-attention flash-in-out': props.rosterLog.employee_company_id !== 1 && workedHoursDisplay.value === 'OPEN'
}));

// Define card classes for different statuses
const cardClasses = computed(() => {
  // Calculate time to start
  const timeToStart = calculateTimeRemaining(generateCurrentTimeunix, props.rosterLog.event_start);
  return {
    // Base style
    'employee-card': true,
    'flex': true,
    'w-full': true,

    // Roster: if upcoming shift is between 1 hour and 3 minutes
    'alert': props.title === 'UPCOMING' && timeToStart <= 60 && timeToStart > 3,
    // Roster: if upcoming shift is between 3 minutes and 1 minute
    'alert2': props.title === 'UPCOMING' && timeToStart <= 3,

    // Leaves: if shift or cover
    'warning': props.title === 'LEAVES' && 
              (props.rosterLog.event_type === 'SHIFT' || props.rosterLog.event_type === 'COVER'),

    // Overtime: if not approved
    'notapproved': props.title === 'OVERTIME' && props.rosterLog.over_below_status !== true,

    // Early: if employee is early for shift
    'early2': props.title === 'EARLY' && 
             props.rosterLog.attendance_status_in === 'EARLY' && 
             props.rosterLog.event_type === 'SHIFT'
  };
});

// Computed: Check if cover shift
const showCoverStatus = computed(() => 
  props.rosterLog.shift_type_in === 'COVER' || props.rosterLog.event_type === 'COVER'
);

// Computed: Check if shift is ongoing or not logout
const checkShiftStatus = computed(() => {
  if (!props.rosterLog.attendance_in) return null;

  const baseTime = formatTime(props.rosterLog.attendance_in);

  if (props.title !== 'NOTLOGOUT' && props.rosterLog.attendance_out !== null) {
    return `${baseTime} to ${formatTime(props.rosterLog.attendance_out)}`;
  } else if (props.title !== 'NOTLOGOUT') {
    return {
      time: baseTime,
      status: 'ON GOING'
    };
  } else if (props.title === 'NOTLOGOUT') {
    return {
      time: baseTime,
      status: 'NOT LOGOUT'
    };
  }
  return baseTime;
});

// Computed: Truncate company name
const truncatedCompanyName = computed(() => {
  if (!props.rosterLog.employee_company_name) return '';
  return props.rosterLog.employee_company_name.length > 20 
    ? props.rosterLog.employee_company_name.substring(0, 20) + '.'
    : props.rosterLog.employee_company_name;
});

// Computed: Format Shift time
const shiftTimeDisplay = computed(() => {
  return `${formatTime(props.rosterLog.event_start)} to ${formatTime(props.rosterLog.event_end)}`;
});

</script>


<style scoped>
.dashboard-sections .employee-card {
  opacity: 1;
}
.dashboard-sections .employee-card:hover {
  background-color: #494949;
  cursor: pointer;
}
.dashboard-sections .employee-card {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #666;
  border-radius: 2px;
  margin-bottom: 4px;
  height: auto;
}
.dashboard-sections .upcoming .employee-card, .dashboard-sections .leaves .employee-card{
  height: 46px;
}
.dashboard-sections .upcoming .employee-content, .dashboard-sections .leaves .employee-content{
  padding-top: 8px;
}
.dashboard-sections .employee-photo-small img {
  width: 41px;
  max-height: 48px;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 3px 0px 0 3px;
}
.dashboard-sections .employee-photo-small {
  position: relative;
  margin-right: 6px;
}
.dashboard-sections .employee-content {
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 5px 0 0 0;
  font-size: 12px;
  letter-spacing: 1px;
  color: #ffffffc9;
}
.dashboard-sections .employee-name {
  margin: 0 0 1px 0;
  padding: 0;
  font-size: 0.8rem !important;
  letter-spacing: -0.5px;
  font-weight: bold;
  color: #ffffffc9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard-sections .employee-checkintime {
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.dashboard-sections .employee-shifttime {
  margin: 0;
  padding: 0;
  opacity: 0.5;
  font-weight: bold;
}
.dashboard-sections .employee-overtimestatus {
  margin: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 28px;
  font-size: 8px;
  padding: 4px 6px;
  background-color: #000;
  text-transform: uppercase;
}
.dashboard-sections .employee-noshowstatus {
  margin: 0;
  padding: 0;
  opacity: 0.5;
  position: absolute;
  right: -1px;
  top: 28px;
  font-size: 8px;
  padding: 4px 3px;
  background-color: #000;
}
.dashboard-sections .employee-cover-status{
  position: absolute;
  background-color: #00caa5;
  bottom: 0;
  font-size: 8px;
  padding: 0px;
  color: #000;
  font-weight: bold;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
}
.dashboard-sections .employee-shift-status{
  position: absolute;
  letter-spacing: 0px;
  background-color: #ffffff7d;
  bottom: 0;
  font-size: 10px;
  color: #000;
  font-weight: bold;
  text-align: center;
  padding: 0;
  width: 100%;
}
.dashboard-sections .employee-overtime-status{
  position: absolute;
  letter-spacing: 1px;
  background-color: #f69700;
  bottom: 0;
  font-size: 8px;
  color: #000;
  font-weight: bold;
  text-align: center;
  padding: 2px;
  width: 100%;
}

/* Style card as per status */
.dashboard-sections .loadingIndicator .employee-card, .dashboard-sections .attendanceLoadingIndicator .employee-card{
  cursor: default;
  opacity: 0.5;
  filter: grayscale(100%);
  -webkit-filter: grayscale(1);
}

.dashboard-sections .employee-timecounter {
  position: absolute;
  width: auto;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #ffffff9b;
  font-weight: bold;
  bottom: 0;
  right: 0;
  text-align: right;
  background-color: #ffffff29;
  padding: 2px 4px;
  font-size: 12px;
  letter-spacing: 0;
  min-width: 56px;
  text-align: center;
}
.dashboard-sections .employee-timecounter i {
  font-size: 10px;
}

.dashboard-sections .workedcounter{
  bottom: 20px;
}
.dashboard-sections .covers .workedcounter{
  bottom: 0;
}
.dashboard-sections .covers .employee-card{
  height: auto;
}

.dashboard-sections .upcoming .warning{
  background-color: #906b0c;
}
.dashboard-sections .upcoming .warning:hover{
  background-color: #c48d00;
}





.dashboard-sections .alert{
  background-color: #58602f !important;
}
.dashboard-sections .alert:hover{
  background-color: #667712 !important;
}
.dashboard-sections .alert2{
  background-color: #692000 !important;
}
.dashboard-sections .alert2:hover{
  background-color: #ac3400 !important;
}

.dashboard-sections .ontime .employee-card{
  background-color: #466c46;
}
.dashboard-sections .ontime .employee-card:hover{
  background-color: #227c22;
}
.dashboard-sections .late .employee-card{
  background-color: #6e4a24;
}
.dashboard-sections .late .employee-card:hover{
  background-color: #c46500;
}
.dashboard-sections .leaves .employee-card{
  background-color: #65466c;
}
.dashboard-sections .leaves .employee-card:hover{
  background-color: #ae15d0;
}
.dashboard-sections .warning{
  background-color: rgb(137, 4, 4) !important;
}
.dashboard-sections .warning:hover{
  background-color: rgb(206, 12, 12) !important;
}
.dashboard-sections .overtime .employee-card{
  background-color: #46516c;
}
.dashboard-sections .overtime .employee-card:hover{
  background-color: #1748c6;
}
.dashboard-sections .overtime .notapproved{
  background-color: #363636 !important;
}
.dashboard-sections .overtime .notapproved:hover{
  background-color: #1748c6 !important;
}
.dashboard-sections .early .employee-card{
  background-color: #9a392b;
}
.dashboard-sections .early .employee-card:hover{
  background-color: #891200;
}
.dashboard-sections .early2{
  background-color: #6f372f !important;
}
.dashboard-sections .early2:hover{
  background-color: #7b1000 !important;
}
.dashboard-sections .notlogout .employee-card{
  background-color: #5d2c3e;
  animation: opacityAnimation 2s infinite ease-in-out;
}
.dashboard-sections .notlogout .employee-card:hover{
  background-color: rgb(133, 38, 105);
}
.dashboard-sections .covers .employee-card{
  background-color: #107161;
}
.dashboard-sections .covers .employee-card:hover{
  background-color: #00a186;
}
.p-button{
    width: 1rem;
    height: 1rem;
    border-radius: 3px;
    padding: 10px 13px;
    margin-bottom: 3px;
    margin-right: 2px;
    background-color: rgba(255, 255, 255, 0.314) !important
}
.dashboard-sections .employee-card .shortcut{
  display: none;
}
.dashboard-sections .employee-card:hover .shortcut{
  display: block;
}
.dashboard-sections .employee-card:hover .employee-timecounter{
  display: none;
}

/* Add media query for screens smaller than 1500px */
@media screen and (max-width: 1650px) {
  .dashboard-sections .employee-name {
    max-width: 150px; /* Adjust this value to fit approximately 18 characters */
  }
}

/* Add media query for screens larger than 1600px */
@media screen and (min-width: 1651px) {
  .dashboard-sections .employee-name {
    max-width: none; /* Allow full width */
  }
}

.worked-hours-error {
  background-color: #ff4444 !important;
}

.worked-hours-attention {
  background-color: #ff0000 !important;
}

</style>

<style>

.dashboard-sections .employee-card .p-button-icon{
  font-size: 12px !important;
}
</style>