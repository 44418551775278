<template>
    <div v-if="showForm" class="mt-4 p-3 border-3 border-200 border-round bg-gray-800">
        <h3 class="text-2xl text-gray-400 font-light mb-3 mt-0 pt-0">Reconcile Complaint</h3>
        <div class="formgrid grid">
            <div class="field col-12">
                <label for="reconcileMessage" class="block mb-2">Message</label>
                <Textarea 
                    id="reconcileMessage" 
                    v-model="reconcileForm.message" 
                    rows="3" 
                    class="w-full" 
                    :disabled="isReconcilingComplaint" 
                />
            </div>
            <div class="col-12">
                <Button 
                    label="Reconcile Complaint" 
                    icon="pi pi-check" 
                    @click="reconcileTicket" 
                    class="mt-3" 
                    :loading="isReconcilingComplaint" 
                    :disabled="isReconcilingComplaint" 
                />
                <Button 
                    label="Cancel" 
                    icon="pi pi-times" 
                    @click="cancelReconcile" 
                    class="mt-3 ml-2 p-button-secondary" 
                    :disabled="isReconcilingComplaint" 
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import { useReconcileComplaint } from '@/composables/complaints/useReconcileComplaint';

const props = defineProps({
    complaint: {
        type: Object,
        required: true
    },
    showForm: {
        type: Boolean,
        required: true
    }
});

const emit = defineEmits(['update:showForm', 'complaint-reconciled']);

const isReconcilingComplaint = ref(false);
const reconcileForm = ref({
    message: '',
    deduction: 0,
    score: 0
});

const { reconcileComplaint } = useReconcileComplaint();

const reconcileTicket = async () => {
    try {
        if (!props.complaint) return;
        isReconcilingComplaint.value = true;

        const updatedComplaint = await reconcileComplaint(props.complaint, reconcileForm.value);
        reconcileForm.value = { message: '', deduction: 0, score: 0 };
        emit('complaint-reconciled', updatedComplaint);
        emit('update:showForm', false);
    } catch (error) {
        console.error('Error reconciling complaint:', error);
    } finally {
        isReconcilingComplaint.value = false;
    }
};

const cancelReconcile = () => {
    reconcileForm.value = { message: '', deduction: 0, score: 0 };
    emit('update:showForm', false);
};
</script>