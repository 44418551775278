<template>
    <div>
        <ConfirmDialog />
        <div class="flex mb-2">
            <div class="flex-0 flex align-items-center justify-content-center">
              <div class="px-0 py-2">
                <h2 class="m-0 p-0 font-light text-600">Complaints for {{ selectedEmployee?.employee_name || 'All Employees' }}</h2>
                <p class="p-0 m-0 pt-1 text-xs text-400"> Showing {{ complaints.length }} results.</p>
              </div>
            </div>
            <div class="flex-1 flex align-items-center gap-2 justify-content-end" v-if="!isDropdownDisabled">
                <InputText
                    v-model="quickSearch"
                    placeholder="Quick Search..."
                    class="w-full md:w-10rem"
                    :showClear="true"
                    @input="onQuickSearch" 
                    v-if="!targetEmployeeId"
                />
                <Dropdown 
                    v-if="!isDropdownDisabled" 
                    v-model="selectedCompany" 
                    :options="companiesList" 
                    :loading="employeesListLoading" 
                    filter 
                    showClear 
                    optionLabel="company_name" 
                    placeholder="Select Company" 
                    class="w-full md:w-16rem" 
                    @change="handleCompanyChange" 
                    @clear="onCompanyClear"
                >
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex align-items-center">
                            {{ slotProps.value.company_name }}
                        </div>
                        <span v-else class="text-sm text-300">
                            {{ slotProps.placeholder }}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div class="flex align-items-center">
                            {{ slotProps.option.company_name }}
                        </div>
                    </template>
                </Dropdown>
                <Dropdown 
                    v-model="selectedEmployee" 
                    :options="filteredEmployees" 
                    optionLabel="employee_name" 
                    placeholder="Select Employee" 
                    class="w-full md:w-16rem"
                    :showClear="true"
                    @change="onEmployeeChange"
                    @clear="onEmployeeClear"
                    v-if="!isDropdownDisabled" 
                />
                <Calendar 
                    v-model="selectedDate" 
                    view="month" 
                    dateFormat="MM yy" 
                    class="w-full md:w-12rem"
                    :disabled="!!selectedEmployee || !!selectedCompany"
                    @date-select="onDateChange"
                    v-if="!isDropdownDisabled" 
                />
                <Button icon="pi pi-plus" v-if="!isDropdownDisabled" @click="openNewComplaintDialog" />
            </div>
        </div>
    </div>
   <div class="flex complaints-view">
        <div class="flex-none mr-2 w-4">
            <DataTable :value="filteredComplaints" dataKey="id" v-model:selection="selectedComplaint" class="w-full" selectionMode="single" :loading="loading" @row-click="onRowClick">
                <Column field="subject" header="Subject" class="w-full">
                    <template #body="slotProps">
                        <div class="flex flex-column gap-1">
                          <span class="text-xs text-400">{{ formatFirebaseDate(slotProps.data.complaint_date || slotProps.data.createdby.timestamp) }}</span>
                          <span class="font-bold">{{ slotProps.data.subject || 'No Subject' }}</span>
                          <span class="text-xs text-400">{{ slotProps.data.employee.name }} - {{ slotProps.data.mailer_status }}</span>
                        </div>
                    </template>
                </Column>
                <Column field="status" header="Status">
                    <template #body="slotProps">
                        <span :class="{
                            'px-2 py-1 text-xs border-round': true,
                            'bg-yellow-500 text-yellow-900': slotProps.data.mailer_status === false,
                            'bg-green-800 text-50': slotProps.data.status === 'Open' && slotProps.data.mailer_status === true,
                            'bg-gray-700 text-400': slotProps.data.status === 'Closed' && slotProps.data.mailer_status === true,
                        }">
                            {{ slotProps.data.mailer_status ? slotProps.data.status : 'Draft'  }}
                        </span>
                    </template>
                </Column>
                <template #empty>
                    <div class="text-500 text-sm">No Complaint found for this filter.</div>
                </template>
            </DataTable>   
        </div>
        <div class="flex-grow-1 bg-gray-800 p-3 border-round" :class="{'border-2 solid border-gray-700': targetEmployeeId}">
                    <div class="p-1" v-if="selectedComplaint">
                        <div class="flex justify-content-between align-items-center mb-3">
                            <div>
                                <h2 class="text-lg m-0 p-0 mb-3 text-400 font-light">Complaint #{{ selectedComplaint.id }}</h2>
                                <div class="text-2xl font-light">{{ selectedComplaint.subject }}</div>
                                <p class="text-300 m-0 p-0 text-sm">{{ formatFirebaseDate(selectedComplaint.complaint_date || selectedComplaint.createdby.timestamp) }}</p>
                            </div>
                            <div class="text-right flex gap-2">
                              <span :class="{
                                  'px-2 py-1 border-round text-sm': true,
                                  'bg-green-800 text-gray-900': selectedComplaint.status === 'Open',
                                  'bg-gray-500 text-gray-900': selectedComplaint.status === 'Closed'
                              }">
                                  {{ selectedComplaint.status }}
                              </span>
                              <span class="px-2 py-1 border-round text-sm bg-gray-500 text-gray-900">
                                  {{ selectedComplaint.priority }}
                              </span>
                              <Button
                                  v-if="!isDropdownDisabled || !canDeleteComplaint"
                                  icon="pi pi-trash" 
                                  class="p-button-complaint-delete"
                                  :disabled="!canDeleteComplaint"
                                  @click="handleDeleteComplaint"
                              >
                                  <i class="pi pi-trash text-xs"></i>
                              </Button>
                            </div>
                        </div>

                        <div class="flex flex-column gap-3">
                            <div v-for="(post, index) in selectedComplaint.posts" :key="index" 
                                 :class="getPostClasses(post)">
                                <div class="flex">
                                    <div class="flex-grow-1">
                                      <div class="flex justify-content-start align-items-center">
                                        <div :class="getPostTypeClasses(post)">
                                            {{ post.type }} Message
                                        </div>
                                        <div v-if="!post.mailer_status" class="w-6rem text-center ml-2 text-xs py-1 px-2 border-round mb-2 bg-gray-500 text-gray-900">
                                            Draft
                                        </div>
                                        <Button 
                                            v-if="!post.mailer_status && !isDropdownDisabled"
                                            icon="pi pi-send" 
                                            label="Publish" 
                                            severity="primary"
                                            @click="handlePublish(selectedComplaint, index)"
                                            :loading="publishingStates[`${selectedComplaint.id}-${index}`]"
                                            class="p-button-complaint-publish mb-2 ml-2"
                                        />
                                        <div v-else class="w-6rem text-center ml-2 text-xs py-1 border-round mb-2 border-1 border-gray-500 text-gray-500">
                                            <i class="pi pi-check text-xs"></i> Published
                                        </div>
                                        <Button 
                                            v-if="!post.mailer_status && post.type !== 'Initial' && !isDropdownDisabled"
                                            icon="pi pi-trash" 
                                            label="Discard" 
                                            severity="danger"
                                            @click="handleDeletePost(selectedComplaint, index)"
                                            :disabled="publishingStates[`${selectedComplaint.id}-${index}`]"
                                            class="p-button-complaint-publish mb-2 ml-2"
                                        />
                                      </div>
                                      <span class="text-400 text-xs block mb-2">by {{ post.email || selectedComplaint.createdby.email }} <span class="text-gray-500 px-2">//</span> {{ formatFirebaseTimestamp(post.timestamp) }}</span>
                                      <p class="line-height-3 m-0 p-0 mt-2 text-2xl" :class="{'text-gray-400': !post.mailer_status}">{{ post.message }}</p>
                                    </div>
                                    <div v-if="post.type === 'Close'" class="flex-none flex flex-column justify-content-center" :class="{'grayscale': hasUnpublishedPosts}">
                                        <span v-if="post.deduction !== undefined" 
                                              :class="['mb-2 px-3 py-1 text-black border-round', 
                                                       post.deduction === 0 ? 'bg-gray-700' : 'bg-red-600']">
                                            Deduction: <strong>{{ post.deduction }}</strong>
                                        </span>
                                        <span v-if="post.score !== undefined" 
                                              :class="['px-3 py-1 text-black border-round', 
                                                       post.score === 0 ? 'bg-gray-700' : 'bg-yellow-600']">
                                            Score: <strong>{{ post.score }}</strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Move the Reconcile button here -->
                        <div v-if="selectedComplaint.status === 'Closed' && !hasUnpublishedPosts && !isDropdownDisabled" class="mb-3 mt-3">
                            <Button 
                                v-if="!showingReconcileForm"
                                label="Reconcile" 
                                icon="pi pi-refresh" 
                                @click="showingReconcileForm = true" 
                                class="p-button-sm" 
                            />
                            
                            <ReconcileComplaintForm
                                :complaint="selectedComplaint"
                                v-model:showForm="showingReconcileForm"
                                @complaint-reconciled="onComplaintReconciled"
                            />
                        </div>
                        <div v-if="selectedComplaint.status === 'Open' && !hasUnpublishedPosts && !isDropdownDisabled" class="mb-3 mt-3">
                            <CloseComplaintForm 
                                :complaint="selectedComplaint"
                            @complaint-closed="onComplaintClosed"
                            />
                        </div>
                        <div v-else>
                          <p class="text-400 text-sm">Publish the draft message to close or reconcile the complaint.</p>
                        </div>
                    </div>
            <div v-else class="w-full">
                <div class="flex align-items-center justify-content-center bg-gray-800 text-400 text-2xl border-round m-2" style="min-height: 200px;">
                    Please select a Complaint.
                </div>
            </div>
        </div>    
    </div>

    <NewComplaintDialog 
        v-model="displayComplaintDialog"
        :preSelectedEmployeeId="props.targetEmployeeId || selectedEmployee?.employee_id"
        @complaint-created="onComplaintCreated"
    />
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, defineProps, watch } from 'vue';
import { auth } from '@/firebaseConfig'; 
import { useEmployees } from '@/composables/api/useEmployees';
import { Timestamp } from 'firebase/firestore';
import { useComplaints } from '@/composables/complaints/useComplaints';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import NewComplaintDialog from '@/components/complaints/NewComplaintDialog.vue';
import CloseComplaintForm from '@/components/complaints/CloseComplaintForm.vue';
import ReconcileComplaintForm from '@/components/complaints/ReconcileComplaintForm.vue';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from "primevue/useconfirm";
import InputText from 'primevue/inputtext';
import { useUserSelections } from '@/composables/useUserSelections';

const props = defineProps({
    targetEmployeeId: {
        type: Number,
        default: 0
    },
    targetCompanyId: {
        type: Number,
        default: 0
    }
});

const {
    complaints,
    loading,
    selectedComplaint,
    fetchComplaints,
    getFilteredComplaints,
    publishComplaint,
    publishingStates,
    deleteComplaint,
    deletePost
} = useComplaints();

const { 
    selectedEmployee, 
    employeesListLoading,
    selectedCompany, 
    companiesList,
    filteredEmployees,
    onEmployeeSelect,
    onEmployeeClear,
    onCompanyChange,
    onCompanyClear
} = useUserSelections();

const searchQuery = ref('');
const displayComplaintDialog = ref(false);


const { employeesList, fetchEmployees, initializeFromLocalStorage } = useEmployees();

const quickSearch = ref('');

const filteredComplaints = computed(() => {
    const complaints = getFilteredComplaints(searchQuery.value);
    if (!quickSearch.value) return complaints;
    
    const searchTerm = quickSearch.value.toLowerCase();
    return complaints.filter(complaint => 
        complaint.subject?.toLowerCase().includes(searchTerm) ||
        complaint.employee?.name?.toLowerCase().includes(searchTerm) ||
        complaint.posts?.some(post => post.message?.toLowerCase().includes(searchTerm)) ||
        complaint.id?.toString().includes(searchTerm)  // Search by complaint ID
    );
});

const openNewComplaintDialog = () => {
  displayComplaintDialog.value = true;
};

const onComplaintCreated = async (newComplaintData) => {
    if (selectedEmployee.value) {
        await fetchComplaints(selectedEmployee.value.employee_id, null, null);
    }
    else {
        await fetchComplaints(null, selectedDate.value, null);
    }
    selectedComplaint.value = newComplaintData;
};

const onRowClick = (event) => {
  selectedComplaint.value = event.data;
};

const showingReconcileForm = ref(false);

const onComplaintReconciled = async (updatedComplaint) => {
    if (selectedEmployee.value) {
        await fetchComplaints(selectedEmployee.value.employee_id, null, null);
    }
    else {
        await fetchComplaints(null, selectedDate.value, null);
    }
    selectedComplaint.value = updatedComplaint;
};

const onComplaintClosed = async (updatedComplaint) => {
    if (selectedEmployee.value) {
        await fetchComplaints(selectedEmployee.value.employee_id, null, null);
    }
    else {
        await fetchComplaints(null, selectedDate.value, null);
    }
    selectedComplaint.value = updatedComplaint;
};

const selectedDate = ref(new Date(new Date().getFullYear(), new Date().getMonth(), 1));

const onDateChange = () => {
    selectedComplaint.value = null;
    quickSearch.value = '';
    if (!selectedEmployee.value) {
        fetchComplaints(null, selectedDate.value, null);
    }
};

const onEmployeeChange = (event) => {
    onEmployeeSelect(event);
    selectedComplaint.value = null;
    quickSearch.value = '';
    if (selectedEmployee.value) {
        fetchComplaints(selectedEmployee.value.employee_id, null, null);
    } else {
        const { companyId } = getFilterParams();
        fetchComplaints(null, null, companyId);
    }
};

const handleCompanyChange = (event) => {
    onCompanyChange(event);
    selectedComplaint.value = null;
    quickSearch.value = '';
    if (selectedCompany.value) {
        fetchComplaints(null, null, selectedCompany.value.company_id);
    } else {
        const { date } = getFilterParams();
        fetchComplaints(null, date, null);
    }
};

const fetchEmployeeData = async () => {
  try {
    await fetchEmployees();
    await initializeFromLocalStorage();
  } catch (error) {
    console.error('Error fetching employees:', error);
  }
};

const formatFirebaseTimestamp = (timestamp) => {
  if (timestamp instanceof Timestamp) {
    const date = timestamp.toDate();
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  } else if (typeof timestamp === 'string') {
    // If the timestamp is stored as an ISO string
    return new Date(timestamp).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  }
  return 'Invalid Date';
};

const formatFirebaseDate = (timestamp) => {
  if (timestamp instanceof Timestamp) {
    const date = timestamp.toDate();
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  } else if (typeof timestamp === 'string') {
    // If the timestamp is stored as an ISO string
    return new Date(timestamp).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  }
  return 'Invalid Date';
};

const handlePublish = async (complaint, postIndex) => {
    try {
        await publishComplaint(complaint, postIndex);
        // Optionally show a success message
    } catch (error) {
        // Handle error
        console.error('Error publishing:', error);
    }
};

const hasUnpublishedPosts = computed(() => {
    if (!selectedComplaint.value?.posts) return false;
    return selectedComplaint.value.posts.some(post => post.mailer_status === false);
});

const getPostClasses = computed(() => {
    return (post) => [
        'w-full p-3 border-round border-3',
        {
            'border-gray-600': !post.mailer_status,
            'border-gray-400': post.type === 'Initial',
            'border-pink-600': post.type === 'Reconcile',
            'border-green-800': post.type === 'Close' && post.deduction === 0 && post.score === 0,
            'border-red-800': post.type === 'Close' && post.deduction > 0 && post.score === 0,
            'border-yellow-800': post.type === 'Close' && post.deduction === 0 && post.score < 0,
            'border-red-500': post.type === 'Close' && post.deduction > 0 && post.score < 0 && post.mailer_status === true
        }
    ];
});

const getPostTypeClasses = computed(() => {
    return (post) => [
        'w-8rem text-xs py-1 px-2 text-left border-round mb-2',
        {
            'bg-gray-500 text-gray-900': !post.mailer_status || post.mailer_status === false,
            'bg-gray-400 text-gray-900': post.type === 'Initial',
            'bg-pink-600': post.type === 'Reconcile',
            'bg-green-800': post.type === 'Close' && post.deduction === 0 && post.score === 0,
            'bg-red-800': post.type === 'Close' && post.deduction > 0 && post.score === 0,
            'bg-yellow-800': post.type === 'Close' && post.deduction === 0 && post.score < 0,
            'bg-red-500': post.type === 'Close' && post.deduction > 0 && post.score < 0 && post.mailer_status === true
        }
    ];
});

const canDeleteComplaint = computed(() => {
    if (!selectedComplaint.value) return false;
    if (selectedComplaint.value.status !== 'Open') return false;
    
    // Check if any posts have been published
    return !selectedComplaint.value.posts?.some(post => post.mailer_status === true);
});

const confirm = useConfirm();

const handleDeleteComplaint = async () => {
    confirm.require({
        header: 'Delete Complaint',
        message: `Do you want to remove '${selectedComplaint.value.subject}' complaint?`,
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-trash',
        acceptClass: 'p-button-danger',
        acceptLabel: 'Delete',
        rejectLabel: 'Cancel',
        accept: async () => {
            try {
                await deleteComplaint(selectedComplaint.value.id);
                selectedComplaint.value = null;
                
                // Refresh the complaints list
                 if (selectedEmployee.value) {
                    await fetchComplaints(selectedEmployee.value.employee_id, null, null);
                } else {
                    await fetchComplaints(null, null, selectedDate.value);
                }
            } catch (error) {
                console.error('Error deleting complaint:', error);
            }
        },
        reject: () => {
            // Optional: Handle rejection
        }
    });
};

const handleDeletePost = async (complaint, postIndex) => {
    confirm.require({
        header: 'Discard Draft Post',
        message: 'Do you want to discard this draft post?',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'pi pi-trash',
        acceptClass: 'p-button-danger',
        acceptLabel: 'Discard',
        rejectLabel: 'Cancel',
        accept: async () => {
            try {
                publishingStates.value[`${complaint.id}-${postIndex}`] = true;
                await deletePost(complaint, postIndex);
                
                // Refresh complaints if needed
                if (selectedEmployee.value) {
                    await fetchComplaints(selectedEmployee.value.employee_id, null, null);
                } else {
                    await fetchComplaints(null, null, selectedDate.value);
                }
            } catch (error) {
                console.error('Error discarding post:', error);
            } finally {
                publishingStates.value[`${complaint.id}-${postIndex}`] = false;
            }
        }
    });
};

const onQuickSearch = (event) => {
    quickSearch.value = event.target.value;
};

const isDropdownDisabled = computed(() => {
    return !!props.targetEmployeeId && props.targetEmployeeId !== 0 || !!props.targetCompanyId && props.targetCompanyId !== 0;
});

const getFilterParams = () => {
    return {
        employeeId: selectedEmployee.value?.employee_id ?? null,
        companyId: selectedCompany.value?.company_id ?? null,
        date: selectedDate.value
    };
};

// Watch: Refresh Data on Prop Change
watch(() => props.targetEmployeeId || props.targetCompanyId, async (newValue) => {
  if (newValue) {
    console.log('fetching complaints for employee', props.targetEmployeeId);
    console.log('fetching complaints for selectedEmployee', selectedEmployee.value);
    console.log('fetching complaints for company', props.targetCompanyId);
    console.log('fetching complaints for selectedCompany', selectedCompany.value);
    if (props.targetEmployeeId) {
        fetchComplaints(props.targetEmployeeId, null, null);
    } else if (props.targetCompanyId) {
        fetchComplaints(null, null, props.targetCompanyId);
    }
  }
}, { immediate: true });

onMounted(async () => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
            if (props.targetEmployeeId || selectedEmployee.value) {
              console.log('fetching complaints for employee', props.targetEmployeeId || selectedEmployee.value.employee_id);
                selectedEmployee.value = employeesList.value.find(
                    emp => emp.employee_id === props.targetEmployeeId || emp.employee_id === selectedEmployee.value.employee_id
                );
                fetchComplaints(props.targetEmployeeId || selectedEmployee.value.employee_id, null, null);
            } else if (props.targetCompanyId || selectedCompany.value) {
              console.log('fetching complaints for company', props.targetCompanyId || selectedCompany.value.company_id);
                selectedCompany.value = companiesList.value.find(
                    comp => comp.company_id === props.targetCompanyId || comp.company_id === selectedCompany.value.company_id
                );
                fetchComplaints(null, null, props.targetCompanyId || selectedCompany.value.company_id );
            } else {
                fetchComplaints(null, selectedDate.value, null);
            }
        } else {
            complaints.value = [];
        }
    });

    onUnmounted(() => unsubscribe());

    await fetchEmployeeData();
    searchQuery.value = '';
});
</script>

<style>
.complaints-view .p-tabview .p-tabview-nav li{
    margin-right: 8px;
}
</style>