import { auth } from '@/firebaseConfig';
import { Timestamp } from 'firebase/firestore';
import { useUpdateComplaint } from './useUpdateComplaint';

export function useReconcileComplaint() {
    const { updateComplaint } = useUpdateComplaint();

    const reconcileComplaint = async (complaint, reconcileData) => {
        try {
            const updatedData = {
                status: 'Open',
                posts: [
                    ...complaint.posts,
                    {
                        type: 'Reconcile',
                        message: reconcileData.message,
                        status: 'Open',
                        timestamp: Timestamp.now(),
                        email: auth.currentUser.email,
                        mailer_status: false
                    }
                ]
            };

            // Update the complaint and get the updated document
            const updatedComplaint = await updateComplaint(complaint.id, updatedData);
            return updatedComplaint;
        } catch (error) {
            console.error('Error reconciling complaint:', error);
            throw error;
        }
    };

    return {
        reconcileComplaint
    };
}
