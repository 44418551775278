// useFetchUpcomingRosters.js
import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';
import { useEmployeePhotos } from '@/composables/useEmployeePhotos';

export function useFetchUpcomingRosters() {
    const data = ref(null);
    const error = ref(null);
    const loading = ref(false);
    const { attachEmployeePhotos } = useEmployeePhotos();

    async function fetchUpcomingRosters() {
        loading.value = true;
        try {
            const url = API_ENDPOINTS.fetchUpcomingRoster;
            
            const response = await axiosClient.get(url);
            if (response.data) {
                // Attach employee photos to the response data
                const dataWithPhotos = attachEmployeePhotos(response.data);
                data.value = dataWithPhotos;
                sessionStorage.setItem('upcomingRosters', JSON.stringify(data.value));
            }
        } catch (err) {
            console.error('Error fetching upcoming rosters:', err);
            error.value = 'Failed to fetch upcoming rosters';
            if (axiosClient.isAxiosError(err)) {
                if (err.response) {
                    console.error('Error response:', err.response.data);
                    console.error('Error status:', err.response.status);
                    error.value = `Server error: ${err.response.status}`;
                } else if (err.request) {
                    console.error('Error request:', err.request);
                    error.value = 'No response from server';
                } else {
                    console.error('Error message:', err.message);
                    error.value = err.message;
                }
            }
        } finally {
            loading.value = false;
        }
    }

    return { data, error, loading, fetchUpcomingRosters };
}