import { auth } from '@/firebaseConfig';
import { Timestamp } from 'firebase/firestore';
import { useUpdateComplaint } from './useUpdateComplaint';

export function useCloseComplaint() {
    const { updateComplaint } = useUpdateComplaint();

    const closeComplaint = async (complaint, closeData) => {
        try {
            const updatedData = {
                status: 'Closed',
                posts: [
                    ...complaint.posts,
                    {
                        type: 'Close',
                        message: closeData.message,
                        status: 'Closed',
                        timestamp: Timestamp.now(),
                        email: auth.currentUser.email,
                        deduction: closeData.deduction,
                        score: closeData.score,
                        mailer_status: false
                    }
                ]
            };

            // Update the complaint and get the updated document
            const updatedComplaint = await updateComplaint(complaint.id, updatedData);

            return updatedComplaint;
        } catch (error) {
            console.error('Error closing complaint:', error);
            throw error;
        }
    };

    return {
        closeComplaint
    };
}
