<template>
    <Dialog 
        :visible="modelValue" 
        @update:visible="$emit('update:modelValue', $event)"
        header="New Complaint" 
        :style="{ width: '50vw' }" 
        :modal="true"
    >
        <div class="formgrid grid">
            <div class="field col-6">
                <label for="employee" class="block mb-2">Employee *</label>
                <Dropdown 
                    id="employee" 
                    v-model="form.employee" 
                    :options="employeesList" 
                    optionLabel="employee_name" 
                    placeholder="Select an Employee" 
                    :loading="employeesListLoading" 
                    class="w-full" 
                    :disabled="isSubmitting || props.preSelectedEmployeeId" 
                />
            </div>
            <div class="field col-6">
                <label for="complaintDate" class="block mb-2">Complaint Date *</label>
                <Calendar 
                    id="complaintDate"
                    dateFormat="yy-mm-dd"
                    v-model="form.complaintDate"
                    :showIcon="true"
                    :disabled="isSubmitting"
                    class="w-full"
                />
            </div>
            <div class="field col-12">
                <label for="subject" class="block mb-2">Subject *</label>
                <InputText 
                    id="subject" 
                    v-model="form.subject" 
                    class="w-full" 
                    :disabled="isSubmitting" 
                    placeholder="Enter complaint subject"
                />
            </div>
            <div class="field col-12">
                <label for="message" class="block mb-2">Message *</label>
                <Textarea 
                    id="message" 
                    v-model="form.message" 
                    rows="5" 
                    class="w-full" 
                    :disabled="isSubmitting" 
                />
            </div>
            <div class="field col-12">
                <label for="priority" class="block mb-2">Priority *</label>
                <Dropdown 
                    id="priority" 
                    v-model="form.priority" 
                    :options="priorities" 
                    placeholder="Select Priority" 
                    class="w-full" 
                    :disabled="isSubmitting" 
                />
            </div>
        </div>
        <template #footer>
            <div class="flex justify-content-between align-items-center w-full">
                <span>
                    <span v-if="form.employee" class="text-gray-400">
                        <div class="flex align-items-l">
                            <div class="flex">
                                <i class="pi pi-envelope mr-2 text-2xl"></i>
                            </div>
                            <div class="flex flex-column text-left">
                                <span class="text-xs text-gray-500 block">Email will be sent to the employee</span>
                                <span class="text-sm text-gray-400 block">{{ form.employee.employee_email }}</span>
                            </div>
                        </div>
                    </span>
                    <span v-else class="text-sm text-gray-600">
                        Select an employee to see its email address here.
                    </span>
                </span>
                <div>
                    <Button 
                        label="Cancel" 
                        class="bg-gray-600 hover:bg-gray-400" 
                        @click="onCancel" 
                        :disabled="isSubmitting" 
                    />
                    <Button 
                        label="Submit" 
                        icon="pi pi-check" 
                        @click="onSubmit" 
                        :disabled="!isFormValid" 
                        :loading="isSubmitting" 
                        autofocus 
                    />
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, watch } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import { useEmployees } from '@/composables/api/useEmployees';
import { useCreateComplaint } from '@/composables/complaints/useCreateComplaint';
import Calendar from 'primevue/calendar';

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    },
    preSelectedEmployeeId: {
        type: Number,
        default: null
    }
});

const emit = defineEmits(['update:modelValue', 'complaint-created']);

const { employeesList, employeesListLoading, fetchEmployees } = useEmployees();
const { createComplaint } = useCreateComplaint();

const priorities = ['Low', 'Medium', 'High'];
const isSubmitting = ref(false);

const form = ref({
    employee: null,
    complaintDate: new Date(),
    subject: '',
    message: '',
    priority: 'Low'
});

const isFormValid = computed(() => {
    return form.value.employee &&
           form.value.complaintDate &&
           form.value.subject.trim() !== '' &&
           form.value.message.trim() !== '' &&
           form.value.priority;
});

const resetForm = () => {
    const preSelectedEmployee = props.preSelectedEmployeeId ? 
        employeesList.value?.find(emp => emp.employee_id === props.preSelectedEmployeeId) : 
        null;

    form.value = {
        employee: preSelectedEmployee || null,
        complaintDate: new Date(),
        subject: '',
        message: '',
        priority: 'Low'
    };
};

const onCancel = () => {
    resetForm();
    emit('update:modelValue', false);
};

const onSubmit = async () => {
    try {
        isSubmitting.value = true;
        const newComplaintData = await createComplaint(form.value);
        emit('complaint-created', newComplaintData);
        resetForm();
        emit('update:modelValue', false);
    } catch (error) {
        console.error('Error creating complaint:', error);
        // You might want to show an error message to the user here
    } finally {
        isSubmitting.value = false;
    }
};

// Fetch employees when component is mounted
fetchEmployees();

// Watch for changes in employeesList and preSelectedEmployeeId
watch(
    [() => employeesList.value, () => props.preSelectedEmployeeId],
    ([newEmployeesList, newPreSelectedEmployeeId]) => {
        if (newEmployeesList && newPreSelectedEmployeeId) {
            const preSelectedEmployee = newEmployeesList.find(
                emp => emp.employee_id === newPreSelectedEmployeeId
            );
            if (preSelectedEmployee) {
                form.value.employee = preSelectedEmployee;
            }
        }
    },
    { immediate: true }
);
</script>
