import { auth } from '@/firebaseConfig';
import { firestoreDB } from '@/firestore';
import { Timestamp } from 'firebase/firestore';

export function useCreateComplaint() {

    const createComplaint = async (complaintData) => {
        try {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                throw new Error('User not authenticated');
            }

            const formattedData = {
                priority: complaintData.priority,
                subject: complaintData.subject,
                complaint_date: complaintData.complaintDate,
                status: 'Open',
                employee: {
                    id: complaintData.employee.employee_id,
                    name: complaintData.employee.employee_name,
                    employee_company_id: complaintData.employee.employee_company_id,
                    employee_company_name: complaintData.employee.employee_company_name,
                    email: complaintData.employee.employee_email
                },
                posts: [
                    {
                        type: 'Initial',
                        message: complaintData.message,
                        status: 'Open',
                        timestamp: Timestamp.now(),
                        mailer_status: false
                    }
                ],
                createdby: {
                    id: currentUser.uid,
                    name: currentUser.displayName || 'Unknown',
                    email: currentUser.email,
                    timestamp: Timestamp.now()
                }
            };

            const docRef = await firestoreDB.addDocument('complaints', formattedData);
            const newComplaintData = { id: docRef, ...formattedData };

            return newComplaintData;
        } catch (error) {
            console.error('Error creating complaint:', error);
            throw error;
        }
    };

    return {
        createComplaint
    };
}
