// composables/api/useSchedule.js
import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';
import { useEmployeePhotos } from '@/composables/useEmployeePhotos';

export function useSchedule() {
  const allSchedule = ref([]);
  const allScheduleLoading = ref(false);
  const allScheduleError = ref(null);
  const { attachEmployeePhotos } = useEmployeePhotos();

  const handleError = (err) => {
    if (err.response && err.response.data && err.response.data.message) {
      return err.response.data.message;
    }
    return 'An unexpected error occurred. Please try again later.';
  };

  const fetchAllSchedule = async (companyId, startDate, endDate) => {
    allScheduleLoading.value = true;
    allScheduleError.value = null;

    try {
      const url = API_ENDPOINTS.fetchSchedule
        .replace('{companyId}', companyId)
        .replace('{startDate}', startDate)
        .replace('{endDate}', endDate);

      const response = await axiosClient.get(url);
      
      if (Array.isArray(response.data)) {
        const dataWithPhotos = attachEmployeePhotos(response.data);
        allSchedule.value = dataWithPhotos;
      } else {
        console.warn('Received non-array data from API:', response.data);
        allSchedule.value = [];
      }
    } catch (err) {
      allScheduleError.value = handleError(err);
      allSchedule.value = [];
    } finally {
      allScheduleLoading.value = false;
    }
  };

  return {
    allSchedule,
    allScheduleLoading,
    allScheduleError,
    fetchAllSchedule
  };
}