// composables/time/useTimeCalculation.js
import { unref } from 'vue'
export function useTimeCalculation() {
  const calculateTimeRemaining = (currentUnixTime, targetUnixTime, minutesString = '') => {
    const now = new Date(unref(currentUnixTime))
    const target = new Date(unref(targetUnixTime))
    const difference = target - now

    if (difference <= 0) {
      const lateDifference = Math.abs(difference)
      if (lateDifference > 0 && lateDifference <= 60000) {
        return '1' + minutesString
      }
      const totalMinutes = Math.ceil(lateDifference / 60000)

      if (minutesString === 'm' && totalMinutes >= 60) {
        const hours = totalMinutes / 60
        const roundedHours = Math.round(hours * 10) / 10
        return roundedHours + 'h'
      }

      return totalMinutes + minutesString
    }

    const totalMinutes = Math.floor(difference / 60000)
    const remainingSeconds = Math.floor((difference % 60000) / 1000)

    const adjustedMinutes = remainingSeconds > 0 ? totalMinutes + 1 : totalMinutes

    if (minutesString === 'm' && adjustedMinutes >= 60) {
      const hours = adjustedMinutes / 60
      const roundedHours = Math.round(hours * 10) / 10
      return roundedHours + 'h'
    }

    return (adjustedMinutes || '0') + minutesString
  }

  const formatTime = (unixTimestamp) => {
    if (unixTimestamp === 0) {
      return '0'
    }

    const date = new Date(unixTimestamp)
    
    const hours = date.getUTCHours().toString().padStart(2, '0')
    const minutes = date.getUTCMinutes().toString().padStart(2, '0')
    return `${hours}:${minutes}`
  }

  const calculateTimeDifferenceInHours = (currentUnixTime, targetUnixTime, hourString = '') => {
    const now = new Date(currentUnixTime)
    const target = new Date(targetUnixTime)
    const difference = target - now
    let totalHours = difference / 3600000
    if (totalHours <= 0) {
      return '0' + hourString
    }
    return totalHours.toFixed(2) + hourString
  }

  return {
    calculateTimeRemaining,
    formatTime,
    calculateTimeDifferenceInHours
  }
}