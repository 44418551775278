export function useEmployeePhotos() {
  const attachEmployeePhotos = (items) => {
    // Get employees from localStorage
    const storedEmployees = localStorage.getItem('employeesList');
    if (!storedEmployees) return items;

    const employeesData = JSON.parse(storedEmployees);
    
    // Map through items and attach photo URLs
    return items.map(item => {
      const matchingEmployee = employeesData.find(
        emp => emp.employee_id === item.employee_id
      );
      
      return {
        ...item,
        employee_photo_url: matchingEmployee?.employee_photo_url || null
      };
    });
  };

  return {
    attachEmployeePhotos
  };
}
