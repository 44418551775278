// composables/time/usePakistanTime.js
import { ref, onUnmounted } from 'vue';

export function usePakistanTime() {
  const pakistanTime = ref('');

  const getPakistanTime = () => {
    const now = new Date()
    const options = {
      timeZone: 'Asia/Karachi',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }
    return new Date(now).toLocaleString('en-PK', options)
  }

  const updateTime = () => {
    pakistanTime.value = getPakistanTime();
  }

  // Initial update
  updateTime();

  // Calculate milliseconds until next minute
  const getMillisecondsUntilNextMinute = () => {
    const now = new Date();
    return (60 - now.getSeconds()) * 1000 - now.getMilliseconds();
  };

  // Set initial timeout to align with minute change
  let timeoutId = setTimeout(() => {
    updateTime();
    // Then start the interval
    intervalId = setInterval(updateTime, 60000);
  }, getMillisecondsUntilNextMinute());

  let intervalId;

  onUnmounted(() => {
    clearTimeout(timeoutId);
    clearInterval(intervalId);
  });

  return {
    pakistanTime
  }
}