import { ref } from 'vue';
import { firestoreDB } from '@/firestore';
import { Timestamp, collection, getDocs, where, query } from 'firebase/firestore';
import { db } from '@/firebaseConfig';
import { useEmailSender } from '@/composables/emails/useEmailSender';

export function useComplaints() {
    const complaints = ref([]);
    const loading = ref(true);
    const selectedComplaint = ref(null);
    const publishingStates = ref({});

    // Fetch complaints
    const fetchComplaints = async (employeeId = null, date = null, companyId = null) => {
        loading.value = true;
        try {
            let q = query(collection(db, 'complaints'));
            
            if (employeeId) {
                q = query(q, where('employee.id', '==', employeeId));
            } else if (companyId) {
                q = query(q, where('employee.employee_company_id', '==', companyId));
            } else if (date) {
                const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
                const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
                q = query(q, 
                    where('complaint_date', '>=', startOfMonth),
                    where('complaint_date', '<=', endOfMonth)
                );
            }

            const snapshot = await getDocs(q);
            complaints.value = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
        } catch (error) {
            console.error('Error fetching complaints:', error);
            complaints.value = [];
        } finally {
            loading.value = false;
        }
    };

    // Get filtered complaints for ViewComplaints.vue
    const getFilteredComplaints = () => {
        return complaints.value.map(complaint => {
            // Check: 
            const hasUnpublishedPost = complaint.posts?.some(post => 
                post.mailer_status === false || post.mailer_status === undefined
            );
            
            // Update: Add mailer_status to the complaint root
            return {
                ...complaint,
                mailer_status: !hasUnpublishedPost,
                hasUnpublishedPost
            };
        }).sort((a, b) => {
            // Update: Sort by unpublished status (unpublished before published)
            if (a.hasUnpublishedPost !== b.hasUnpublishedPost) {
                return a.hasUnpublishedPost ? -1 : 1;
            }

            // Update: Sort by status (Open before Reconcile before Closed)
            const statusOrder = { 'Open': 0, 'Reconcile': 1, 'Closed': 2 };
            const statusComparison = statusOrder[a.status] - statusOrder[b.status];
            
            if (statusComparison !== 0) {
                return statusComparison;
            }
            
            // Update: If status is the same, sort by date (newest to oldest)
            const dateA = new Date(a.complaint_date.toDate()).getTime();
            const dateB = new Date(b.complaint_date.toDate()).getTime();
            return dateB - dateA;
        });
    };

    const publishComplaint = async (complaint, postIndex) => {
        try {
            // Update: Set publishing state to true
            publishingStates.value[`${complaint.id}-${postIndex}`] = true;

            // Update: Send email
            const { sendEmail } = useEmailSender();
            await sendEmail(
                complaint.employee.email,
                `Complaint #${complaint.id} ${complaint.posts[postIndex].type}`,
                complaint
            );

            // Update: Update the post with mailer_status true and timestamp
            const updatedPosts = [...complaint.posts];
            updatedPosts[postIndex] = {
                ...updatedPosts[postIndex],
                mailer_status: true,
                timestamp: Timestamp.now()
            };

            // Update: Update the document in Firestore
            await firestoreDB.updateDocument('complaints', complaint.id, {
                posts: updatedPosts
            });

            // Update: Fetch fresh complaints
            await fetchComplaints();
            
            // Update: Update selectedComplaint with fresh data
            selectedComplaint.value = complaints.value.find(c => c.id === complaint.id);

        } catch (error) {
            console.error('Error publishing complaint:', error);
            throw error;
        } finally {
            publishingStates.value[`${complaint.id}-${postIndex}`] = false;
        }
    };

    // Function: Delete a complaint
    const deleteComplaint = async (complaintId) => {
        try {
            await firestoreDB.deleteDocument('complaints', complaintId);
            // Update: Remove the complaint from the local array
            complaints.value = complaints.value.filter(c => c.id !== complaintId);
        } catch (error) {
            console.error('Error deleting complaint:', error);
            throw error;
        }
    };

    const deletePost = async (complaint, postIndex) => {
        try {
            // Remove the post from the posts array
            const updatedPosts = [...complaint.posts];
            const deletedPost = updatedPosts[postIndex];
            updatedPosts.splice(postIndex, 1);

            // Update: Determine the new status based on the deleted post and current status
            let newStatus = complaint.status;
            
            // Check: If we're deleting a draft post
            if (!deletedPost.mailer_status) {
                if (deletedPost.type === 'Reconcile' && complaint.status === 'Open') {
                    newStatus = 'Closed';
                } else if (deletedPost.type === 'Initial' && complaint.status === 'Open') {
                    newStatus = 'Open';
                } else if (deletedPost.type === 'Close' && complaint.status === 'Closed') {
                    newStatus = 'Open';
                }
            }

            // Update: Update the document in Firestore with both posts and new status
            await firestoreDB.updateDocument('complaints', complaint.id, {
                posts: updatedPosts,
                status: newStatus
            });

            // Update: Update the local state
            const updatedComplaint = {
                ...complaint,
                posts: updatedPosts,
                status: newStatus
            };

            // Update: Update the complaints array
            complaints.value = complaints.value.map(c => 
                c.id === complaint.id ? updatedComplaint : c
            );

            // Update: Update selected complaint
            selectedComplaint.value = updatedComplaint;

        } catch (error) {
            console.error('Error deleting post:', error);
            throw error;
        }
    };

    return {
        complaints,
        loading,
        selectedComplaint,
        fetchComplaints,
        getFilteredComplaints,
        publishComplaint,
        publishingStates,
        deleteComplaint,
        deletePost
    };
}
