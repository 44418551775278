<template>
  <div class="flex mb-2">
    <div class="flex-0 flex align-items-center justify-content-center">
      <div class="px-0 py-2">
        <h2 class="m-0 p-0 font-light text-600">{{ props.targetCompanyName }} Schedule</h2>
        <p class="p-0 m-0 pt-1 text-xs text-300">Showing {{ processedRosterData.length }} results.</p>
      </div>
    </div>
    <div class="flex-1 flex align-items-center gap-2 justify-content-end">
      <Calendar 
        v-model="selectedDate" 
        view="month" 
        dateFormat="MM yy" 
        :minDate="minDate"
        :maxDate="maxDate"
        :manualInput="false"
      />
      <Button icon="pi pi-camera" :disabled="isLoading" @click="exportPNG" class="surface-300 hover:surface-500" />
      <Button icon="pi pi-refresh" :loading="isLoading" @click="refreshData" class="surface-300 hover:surface-500" />
    </div>
  </div>
  <div v-if="isLoading">
    <div class="imab-logo-animated">
      <div><span class="I">I</span><span class="M">M</span><span class="A">A</span><span class="B">B</span></div>
    </div>
  </div>
  <div v-else-if="processedRosterData.length">
    <DataTable :value="processedRosterData" :loading="isLoading" selectionMode="single" rowGroupMode="rowspan" class="hover:surface-50" groupRowsBy="week">
      <Column field="week" header="Week Number" style="width:180px" class="bg-black-alpha-20 text-center border-right-2">
        <template #body="slotProps">
          <div v-if="slotProps.data.weekRowSpan">
            <div>Week {{ slotProps.data.week }}</div>
            <div>{{ slotProps.data.weekRange }}</div>
          </div>
        </template>
      </Column>
      <Column field="employee_name" header="Employee Name" style="width:320px">
        <template #body="slotProps">
          <div class="flex gap-2">
            <img alt="flag" :src="slotProps.data.employee_photo_url || defaultUserImage" class="max-w-2rem max-h-2rem employee-photo" />
            <div>
              <div class="font-bold">{{ slotProps.data.employee_name }}</div>
              <div class="text-300">{{ slotProps.data.employee_company_name }}</div>
            </div>
          </div>
        </template>
      </Column>
      <Column v-for="day in weekDays" :key="day" :field="day" :header="day" style="width:150px" class="border-left-2 text-center">
        <template #body="slotProps">
          <div :class="{
            'border-bottom-3 border-primary-500': isToday(slotProps.data[day].roster_start) && slotProps.data[day].shift_type !== 'LEAVE' && slotProps.data[day].roster_marked === false,
            'border-bottom-3 border-orange-500': isToday(slotProps.data[day].roster_start) && slotProps.data[day].shift_type === 'LEAVE' && slotProps.data[day].roster_marked === false,
            'border-bottom-3 border-orange-800': isFuture(slotProps.data[day].roster_start) && slotProps.data[day].shift_type === 'LEAVE' && slotProps.data[day].roster_marked === false,
            'border-bottom-3 border-300': isToday(slotProps.data[day].roster_start) && slotProps.data[day].roster_marked === true,
            '': isFuture(slotProps.data[day].roster_start)
          }">
            <template v-if="slotProps.data[day].start && slotProps.data[day].end">
              <div :class="{
                            'line-through text-200': isPastShift(slotProps.data[day].roster_start) && (slotProps.data[day].roster_marked===true || slotProps.data[day].shift_type === 'LEAVE'),
                            'text-400': isToday(slotProps.data[day].roster_start) && slotProps.data[day].roster_marked===true,
                            'font-bold text-600': isToday(slotProps.data[day].roster_start) && slotProps.data[day].roster_marked===false,
                            'font-bold text-400': isFuture(slotProps.data[day].roster_start)}">
                {{ slotProps.data[day].start }} - {{ slotProps.data[day].end }}
              </div>
              <div :class="{
                            'text-xs text-500 mb-1 font-bold': isToday(slotProps.data[day].roster_start) && slotProps.data[day].roster_marked===false && (slotProps.data[day].shift_type === 'SHIFT' || slotProps.data[day].shift_type === 'LEAVE'),
                            'text-xs text-300 mb-1': isToday(slotProps.data[day].roster_start) && slotProps.data[day].roster_marked===true,
                            'text-xs text-200 mb-1': isFuture(slotProps.data[day].roster_start) && (slotProps.data[day].shift_type === 'SHIFT' || slotProps.data[day].shift_type === 'LEAVE'),
                            'text-xs text-100 mb-1': isPastShift(slotProps.data[day].roster_start) && (slotProps.data[day].shift_type === 'SHIFT' || slotProps.data[day].shift_type === 'LEAVE'),
                            'text-xs text-cyan-700 mb-1': slotProps.data[day].shift_type === 'COVER'}">
                {{ slotProps.data[day].shift_type }}
                <span v-if="slotProps.data[day].roster_marked===true" :class="{
                            'text-100 text-xs': isPastShift(slotProps.data[day].roster_start) && slotProps.data[day].roster_marked===true,
                            'text-300 text-xs': isToday(slotProps.data[day].roster_start) && slotProps.data[day].roster_marked===true
                  }">DONE</span>
              </div>
            </template>
            <template v-else>
              <div class="font-bold mb-1 text-100">DAY OFF</div>
            </template>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
  <div v-else>
    No Schedule found for this filter.
  </div>
</template>

<script setup>
import { ref, computed, watch, defineProps, onMounted } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useSchedule } from '@/composables/api/useSchedule';
import { useExportScheduleImage } from '@/composables/useExportScheduleImage';
import { useTimeCalculation } from '@/composables/time/useTimeCalculation';
import defaultUserImage from '@/assets/default-user.png';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';

const props = defineProps({
  targetCompanyId: {
    type: Number,
    required: true
  },
  targetCompanyName: {
    type: String,
    required: true
  }
});

const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const { allSchedule, allScheduleLoading, allScheduleError, fetchAllSchedule } = useSchedule();
const { exportTableAsImage } = useExportScheduleImage();
const { formatTime } = useTimeCalculation();
const toast = useToast();

const isLoading = computed(() => allScheduleLoading.value);
const selectedDate = ref(new Date());

const minDate = computed(() => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  return date;
});

const maxDate = computed(() => {
  const date = new Date();
  date.setMonth(date.getMonth() + 2);
  return date;
});

const startDate = computed(() => {
  const date = new Date(selectedDate.value);
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  return date.getTime();
});

const endDate = computed(() => {
  const date = new Date(selectedDate.value);
  date.setMonth(date.getMonth() + 1, 0);
  date.setHours(23, 59, 59, 999);
  return date.getTime();
});

const isPastShift = (rosterStart) => {
  if (!rosterStart) return false;
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return new Date(rosterStart) < today;
};

const isToday = (rosterStart) => {
  if (!rosterStart) return false;
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const shiftDate = new Date(rosterStart);
  return shiftDate >= today && shiftDate < tomorrow;
};

const isFuture = (rosterStart) => {
  if (!rosterStart) return false;
  const tomorrow = new Date();
  tomorrow.setHours(0, 0, 0, 0);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return new Date(rosterStart) >= tomorrow;
};

const formatDate = (date) => {
  return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' });
};

const getWeekDates = (date) => {
  const start = new Date(date);
  start.setDate(start.getDate() - start.getDay() + 1); // Monday
  const end = new Date(start);
  end.setDate(end.getDate() + 6); // Sunday
  return `${formatDate(start)} - ${formatDate(end)}`;
};

const getWeekNumber = (date) => {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  return Math.ceil((date.getDate() + firstDayOfMonth.getDay() - 1) / 7);
};

const processedRosterData = computed(() => {
  if (isLoading.value || allScheduleError.value) {
    return [];
  }

  const sortedRosterData = [...allSchedule.value].sort((a, b) => a.roster_start - b.roster_start);
  const processedData = [];
  const employeeWeeks = {};
  let currentWeek = null;
  let weekRowSpan = 0;

  sortedRosterData.forEach(shift => {
    const shiftDate = new Date(shift.roster_start);
    const weekNumber = getWeekNumber(shiftDate);
    const employeeWeekKey = `${shift.employee_id}-${weekNumber}`;

    if (weekNumber !== currentWeek) {
      currentWeek = weekNumber;
      weekRowSpan = 0;
    }

    if (!employeeWeeks[employeeWeekKey]) {
      weekRowSpan++;
      employeeWeeks[employeeWeekKey] = {
        employee_id: shift.employee_id,
        employee_name: shift.employee_name,
        employee_company_name: shift.employee_company_name,
        employee_photo_url: shift.employee_photo_url,
        week: weekNumber,
        roster_start: shift.roster_start,
        weekRange: getWeekDates(shiftDate),
        weekRowSpan: weekRowSpan === 1 ? 1 : null
      };
      weekDays.forEach(day => {
        employeeWeeks[employeeWeekKey][day] = { start: null, end: null, shift_type: null };
      });
      processedData.push(employeeWeeks[employeeWeekKey]);
    }

    employeeWeeks[employeeWeekKey][shift.roster_day] = {
      start: formatTime(shift.roster_start),
      end: formatTime(shift.roster_end),
      roster_start: shift.roster_start,
      shift_type: shift.shift_type,
      roster_marked:  shift.roster_marked
    };
  });

  // Update rowspans
  let lastWeek = null;
  let weekSpan = 0;
  processedData.forEach((item, index) => {
    if (item.week !== lastWeek) {
      if (lastWeek !== null) {
        processedData[index - weekSpan].weekRowSpan = weekSpan;
      }
      lastWeek = item.week;
      weekSpan = 1;
    } else {
      weekSpan++;
    }
  });
  if (weekSpan > 0) {
    processedData[processedData.length - weekSpan].weekRowSpan = weekSpan;
  }

  return processedData;
});

const refreshData = async () => {
  try {
    await fetchAllSchedule(props.targetCompanyId, startDate.value, endDate.value);
  } catch (err) {
    handleError(err, 'Fetching Rosters');
  }
};

const handleError = (error, context) => {
  console.error(`Error in ${context}:`, error);
  
  let errorMessage = 'An unexpected error occurred.';
  if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  }

  toast.add({
    severity: 'error',
    summary: `Error: ${context}`,
    detail: errorMessage,
    life: 12000
  });
};

const exportPNG = async () => {
  if (isLoading.value) return;
  
  const fileName = `${props.targetCompanyName}_Schedule_${selectedDate.value.toISOString().slice(0,7)}.png`;
  try {
    await exportTableAsImage(processedRosterData.value, weekDays, fileName, props.targetCompanyName);
    toast.add({
      severity: 'success',
      summary: 'Export Successful',
      detail: 'The schedule has been exported as an image.',
      life: 3000
    });
  } catch (error) {
    console.error('Error exporting image:', error);
    toast.add({
      severity: 'error',
      summary: 'Export Failed',
      detail: 'An error occurred while exporting the schedule.',
      life: 5000
    });
  }
};

watch(() => props.targetCompanyId, async (newValue) => {
  if (newValue) {
    await refreshData();
  }
});

watch(selectedDate, async () => {
  await refreshData();
});

watch(allScheduleError, (newError) => {
  if (newError) {
    handleError(newError, 'Roster');
  }
});

onMounted(async () => {
  console.log('Component mounted. targetCompanyId:', props.targetCompanyId);
  try {
    await refreshData();
  } catch (error) {
    handleError(error, 'Component Initialization');
  }
});
</script>