<!-- SectionStatus.vue -->
<template> 
  <div class="employee-status-section" :class="sectionClass">
    <div :class="{ 'loadingIndicator': loadingIndicator, 'attendanceLoadingIndicator': attendanceLoadingIndicator, 'status-category': true }">
      <h2 class="status-title"><Badge>{{ records.length }}</Badge> {{ title }} </h2>
      <div class="employee-cards">
        <div v-if="records.length > 0" class="timeline">
          <EmployeeCard 
            v-for="rosterLog in records" 
            :key="rosterLog.event_id"
            :rosterLog="rosterLog"
            :showCounter="showCounter"
            :loadingIndicator="loadingIndicator"
            @updateSuccess="handleUpdateSuccess"
            :title="title" />
        </div>
        <div class="nodata" v-else>
            No employees data to display.
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { defineProps, defineEmits } from 'vue';
  import Badge from 'primevue/badge';
  import EmployeeCard from '@/components/dashboard/EmployeeCard.vue';

  defineProps({
    title: String,
    records: Array,
    defaultUserImage: String,
    sectionClass: String,
    showCounter: Boolean,
    loadingIndicator: Boolean,
    attendanceLoadingIndicator: Boolean
  });

  // Emit
  const emit = defineEmits(['updateSuccess']);

  const handleUpdateSuccess = () => {
    emit('updateSuccess');
  };
  
</script>

<style scoped>
  .dashboard-sections .status-category {
    flex: 1;
    margin-right: 6px;
  }
  .dashboard-sections .status-title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    color: #AAA;
    letter-spacing: 3px;
  }

.dashboard-sections .p-badge{
  background-color: #555;
  text-align: center;
  margin-right: 6px;
  padding: 0 0 0 2px;
}
.dashboard-sections .ontime .p-badge{
  background-color: #466c46;
}
.dashboard-sections .late .p-badge{
  background-color: #6e4a24;
}
.dashboard-sections .noshow .p-badge{
  background-color: #793838;
}
.dashboard-sections .onleave .p-badge{
  background-color: #4e4e4e;
}
.dashboard-sections .overtime .p-badge{
  background-color: #46516c;
}
.dashboard-sections .early .p-badge{
  background-color: #6f372f;
}
.dashboard-sections .notlogout .p-badge{
  background-color: #616161;
  animation: opacityAnimation 2s infinite ease-in-out;
}
.dashboard-sections .leaves .p-badge{
  background-color: #65466c;
}
.dashboard-sections .covers .p-badge{
  background-color: #107161;
}
</style>